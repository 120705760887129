import { apiClient } from "./ApiService";
import {userToken} from "../Util/userToken"

export const uploadProgramsFiles = async (payload) => {
    const JWTTOKEN = await userToken()
    return await apiClient.post(`/api/v1/files/upload`,payload, {headers:{"access-token":JWTTOKEN,'Content-Type': 'multipart/form-data'}});
}

export const uploadImageFiles = async (payload) => {
    const JWTTOKEN = await userToken()
    return await apiClient.post(`/api/v1/files/upload`,payload, {headers:{"access-token":JWTTOKEN,'Content-Type': 'multipart/form-data'}});
}