import React from 'react'

function Footer(props) {
  return (
    <footer className='footer' style={{position: props.height ? 'relative':'fixed'}} >
        <p>&copy; 2024 <b>CHAMBER SKILLS</b> All Right Reserved.</p>
    </footer>
  )
}

export default Footer