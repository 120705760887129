/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { AppContext } from '../Context/AppContext'
import GalleryManage from '../Components/GalleryManage'
import GalleryShow from '../Components/GalleryShow'
function Gallery() {
    const {loginUser} = useContext(AppContext)
  return (
    <>
        <section className="gallery-container">
        { loginUser ?  <GalleryManage/>  : <GalleryShow/>}
        </section>
    </>
  )
}

export default Gallery