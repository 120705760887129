const common = {
    add: (resource) => `A New ${resource} Added Successfully`,
    updated: (resource) => `${resource} Updated Successfully`,
    deleted: (resource) => `${resource} Deleted Successfully`,
}
const error ={
    400:`Bard Request`,
    404:(resource)=>`${resource} Not Found`,
    409:(resource)=>`${resource} Conflict By Code`,
    topic409:(resource)=>`${resource} Conflict By Topic`,
    500:`Internal Server Error`
}
const type ={
    programs:`Programs`,
    album:`Album`,
    image:`Image`,
    attachment:`Attachment`,
    contactUs:`Contact Us`,
    user :`User`
}

const contactUs = {
    add: `Your Message Was Successfully Sent. We Will Contact You Soon.`
}
const user = {   
    found:`You Have Successfully Logged In.`,
    notFound: `Invalid Email Or Password`
}
const programs = {
    add: `A New Programs Added Successfully`,
    updated: `Programs Updated Successfully`,
    deleted: `Programs Deleted Successfully`,    
    notFound: `Program Not Found`,
    conflictByCode : `Program Conflict By Code`,
    patchById: `Program Patch By Code`
}




module.exports = {
    common,
    error,
    type,
    contactUs,
    programs,
    user
}