/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Heading from '../Components/Heading'
import { AppContext } from '../Context/AppContext';
import ProgramsPage from '../Components/ProgramsPage';
import ProgramUpdateForm from '../Components/ProgramUpdateForm';
import ProgramAddForm from '../Components/ProgramAddForm';
import ProgramDelete from '../Components/ProgramDelete';
import ReactPaginate from 'react-paginate';

const ProgramsManage = () => {    
    const {programs=[], allProgramsCount=0, isCheckEditProgramPage, isStaticPrograms, dispatchEvent } = useContext(AppContext)

    const [updatePrograms, setUpdatesPrograms] = useState({})
    const [addProgramVisible, setAddProgramVisible] = useState(false)
    const [updateProgramVisible, setUpdateProgramVisible] = useState(false)
    const [deleteProgramVisible, setDeleteProgramVisible] = useState(false)
    const [deleteProgramData, setDeleteProgram] = useState([])
    const [pageCount, setPageCount] = useState(1)
    const [totalPages, setTotalPages] = useState()
    const [sortOrder, setSortOrder] = useState(`ASC`)
    const [sortBy, setSortBy] = useState(`_id`)


    useEffect(()=>{
        allProgramsCount%7 !== 0 ? setTotalPages(Math.floor(allProgramsCount/7)+1) : setTotalPages(Math.floor(allProgramsCount/7))
    },[allProgramsCount, pageCount, programs.length])

    useEffect(()=>{
        if(isCheckEditProgramPage){
            callPrograms()
        }
    },[pageCount, sortOrder, sortBy,isCheckEditProgramPage])

    function handleUpdate(programUpdateData) {
        setUpdatesPrograms(programUpdateData)
        setUpdateProgramVisible(true)
    }

    function handleAddProgrammes(){
        setAddProgramVisible(true)
    }

    const handleDeleteProgramme = async(deleteProgramData)=>{
        setDeleteProgramVisible(true)
        setDeleteProgram(deleteProgramData)
    }
    
    function handlePreviewProgrammes(){
        dispatchEvent('IS_CHECK_EDIT_PROGRAM_PAGE', false )
    }
    const loadProgramsPages = (pageSide) => {
        const page = pageSide.selected+1
        setPageCount(page)
    }

    const handleSortOrder =(e)=>{
        setSortOrder(e.target.value)
    }
    const handleSortBy =(e)=>{
        setSortBy(e.target.value)
    }
    const callPrograms = ()=>{
        dispatchEvent("GET_ALL_PROGRAMS_BY_TOKEN",{page:pageCount, limit:7, sortOrder:sortOrder,sortBy:sortBy})
    }
  return (
    isCheckEditProgramPage ? 
    <>
        <div className="main-component-body d-flex flex-column align-items-center">
            <Heading>UPDATE PROGRAMS</Heading>
            <div className="programmes-edit-container">
                <button className="edit-buttons preview-buttons" onClick={()=>handlePreviewProgrammes()}>PREVIEW</button>
                <div className="programmes-edit-top-menu">
                    <p className="programmes-edit-top-menu-text">Total Programs {allProgramsCount}</p>
                    <button className="edit-buttons add-buttons" onClick={()=>handleAddProgrammes()}>ADD NEW +</button>
                </div>
                <div className="d-flex flex-row programmes-sort-box"> 
                    <div className="d-flex flex-column me-4 programmes-order-by fw-bold">
                        <label>Order By</label>
                        <select  disabled={isStaticPrograms} onChange={(e)=>handleSortOrder(e)} className="text-center" >
                            <option value={`ASC`}>ASCENDING</option>
                            <option value={`DESC`}>DESCENDING</option>
                        </select>
                    </div>
                    <div className="d-flex flex-column programmes-order-by fw-bold">
                        <label>Sort By</label>
                        <select disabled={isStaticPrograms} onChange={(e)=>handleSortBy(e)} className="text-center">
                            <option value={`_id`}>ID</option>
                            <option value={`code`}>CODE</option>
                            <option value={`topic`}>TOPIC</option>
                        </select>
                    </div>
                </div>
                <div className="programmes-table-scroll-view">
                    <table className="table table-light table-striped table-hover" style={{position: 'relative'}}>
                        <thead style={{position:"sticky",top:"0"}}>
                            <tr>
                                <th>#</th>
                                <th>Code</th>
                                <th>Topic</th>
                                <th className="text-center text-nowrap">Created Date</th>
                                <th className="text-center text-nowrap">Updated Date</th>
                                <th className="text-center">Action</th>
                            </tr>
                        </thead><tbody className="table-group-divider table-body-responsive">
                            {!isStaticPrograms ? programs?.slice(0,7)?.map((data, index)=>(
                                <tr key={index}>
                                    <td className='table-data-container'>{((pageCount-1)*7 + index + 1)}</td>
                                    <td className='table-data-container'>{data?.code}</td>
                                    <td className='table-data-container'>{data?.topic}</td>
                                    <td className='table-data-container' style={{textWrap:'nowrap'}}>{data?.createdAt?.split('T')[0]}</td>
                                    <td className='table-data-container' style={{textWrap:'nowrap'}}>{data?.updatedAt?.split('T')[0]}</td>
                                    <td className='table-data-container'>
                                        <div className="table-data-button-box">
                                            <button className="edit-buttons update-buttons" onClick={()=>handleUpdate(data)}>PREVIEW / EDIT</button>
                                            <button className="edit-buttons delete-buttons" onClick={()=>handleDeleteProgramme(data)}>DELETE</button>
                                        </div>
                                    </td>
                                </tr>
                            )): <tr>
                                    <td  colSpan="6" className='table-data-container'>
                                        <h1 style={{textAlign: 'center',color:'var(--color-text-on-gray-light)'}}>Add New Programme</h1>
                                    </td>
                                </tr>}
                        </tbody>
                    </table>
                </div>
                {isStaticPrograms? '':
                    <div className="d-flex flex-row align-items-center justify-content-center mt-4">
                        <ReactPaginate 
                            pageCount={totalPages}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            onPageChange={loadProgramsPages}
                            breakLabel={"..."}
                            nextLabel={"NEXT >"}
                            previousLabel={"< PREVIOUS"}
                            renderOnZeroPageCount={null}
                            className={"pagination-container"}
                            pageClassName={"pagination-page"}
                            pageLinkClassName={"pagination-link"}
                            activeClassName={"pagination-page-active"}
                            activeLinkClassName={"pagination-link-active"}
                            previousClassName={"pagination-previous-next-container"}
                            nextClassName={"pagination-previous-next-container"}
                            previousLinkClassName={"pagination-previous-next-link"}
                            nextLinkClassName={"pagination-previous-next-link"}
                            disabledClassName={"pagination-previous-next-disable"}
                        />
                    </div>
                }
            </div>
        </div>
        <ProgramAddForm
            visible={addProgramVisible}
            close={setAddProgramVisible}
            page={{pageCount,sortOrder,sortBy}}
        />
        <ProgramUpdateForm
            visible={updateProgramVisible}
            close={setUpdateProgramVisible}
            defaultValues={updatePrograms}
            page={{pageCount,sortOrder,sortBy}}
        />
        <ProgramDelete
            visible={deleteProgramVisible}
            close={setDeleteProgramVisible}
            deleteProgramData={deleteProgramData}
            page={{pageCount,sortOrder,sortBy}}
        />
    </>
       : <ProgramsPage/>
  ) 
}

export default ProgramsManage