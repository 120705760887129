import React, { useEffect, useState } from 'react'

function ScaleImage({image, visible, close}) {
    const [imageWidth, setImageWidth] = useState();
    const [imageHeight, setImageHeight] = useState();
    const [landscape, setLandscape] = useState(false);
    const [portrait, setPortrait] = useState(false);

    const img = new Image();
    img.src =`/${image}`
    useEffect(()=>{
        setImageWidth(img.naturalWidth);
        setImageHeight(img.naturalHeight);
        var imgWidthHref = (imageWidth/4) + imageWidth
        var imgHeightHref = (imageHeight/4) + imageHeight
        if(imgWidthHref < imageHeight){
            setLandscape(false)
            setPortrait(true)
        }
        if(imgHeightHref < imageWidth){
            setPortrait(false)
            setLandscape(true)
        }
    },[imageWidth,imageHeight, img.naturalWidth, img.naturalHeight,image])
    
  return (

    <div className="gallery-modal-blur-background" onClick={()=>close(false)} style={{display: visible ? 'flex' : 'none'}}>
        <div className="gallery-image-modal-box">
            <button className = 'gallery-image-modal-close' onClick={()=>close(false)}>X</button>
            <img src={`/${image}`} alt="" className={`gallery-popup-modal ${landscape ? ' gallery-popup-modal-width':''} ${ portrait ? ' gallery-popup-modal-height':''}`}/>
        </div>
    </div>
  )
}

export default ScaleImage