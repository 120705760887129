import React from 'react'

function LoadingPage() {
  return (
    <div className="loading-page-box">
        <img src="/assets/icons/loader.svg" alt="Loading" className="loading-page-image"/>
    </div>
  )
}

export default LoadingPage