import React, { useContext } from 'react'
import GalleryAlbum from './GalleryAlbum'
import Heading from './Heading'
import { AppContext } from '../Context/AppContext'

const GalleryShow = ({close}) => {
    const {  loginUser } = useContext(AppContext)
  return (
        <>
            <Heading>GALLERY</Heading>
            { loginUser ? 
                <div style={{position:"relative", width:"100%", height:"50px"}}>
                    <button className="edit-buttons preview-buttons" onClick={()=>close()}>Go To Edit</button>
                </div> 
            : '' }
            <GalleryAlbum/>
        </>
  )
}

export default GalleryShow