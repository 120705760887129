function Heading(props) {
  return (
    <div className="main-component-header">
        <h1>
            {props.children}
        </h1>
    </div>
  )
}

function SubHeading(props) {
  return (
    <div className="main-component-header">
        <h2 className="text-center">
            {props.children}
        </h2>
    </div>
  )
}
function SubHeadingForme(props) {
  return (
    <div className="main-component-header" style={{width:"80%"}}>
        <h3 className="text-center">
            {props.children}
        </h3>
    </div>
  )
}
export {SubHeading,SubHeadingForme}
export default Heading