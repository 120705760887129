/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import AlbumCard from './AlbumCard'
import Album from './Album'
import { AppContext } from '../Context/AppContext'
import ReactPaginate from 'react-paginate'

const GalleryAlbum = () => {
    const [isShowAlbum, setIsShowAlbum] = useState(false)
    const [selectedAlbumsData, setSelectedAlbumsData] = useState([])
    const [albumPageCount, setAlbumPageCount] = useState(1)
    const [totalPages, setTotalPages] = useState(1)
    
    const {  dispatchEvent, albums=[], albumsCount} = useContext(AppContext)
    useEffect(()=>{
        dispatchEvent('GET_ALBUMS')
        setTimeout(()=>{
            dispatchEvent('CHECK_PAGE_HEIGHT')
        },100)
    },[])
    useEffect(()=>{
        albumsCount%12 !== 0 ? setTotalPages(Math.floor(albumsCount/12)+1) : setTotalPages(Math.floor(albumsCount/12))
    },[albumsCount,albums])

    useEffect(()=>{
        dispatchEvent('CHECK_PAGE_HEIGHT')
    },[isShowAlbum])

    const selectAlbum =(album)=>{
        setIsShowAlbum(!isShowAlbum)
        setSelectedAlbumsData(album)
    }
    const loadAlbumPagination = (pageCont) => {
        const page = pageCont.selected+1
        setAlbumPageCount(page)
        dispatchEvent("GET_ALBUMS",{page:page, limit:12})
    }
    return isShowAlbum ?<Album album={selectedAlbumsData} close={selectAlbum}/> : (
        <>
        <div className="d-flex flex-wrap align-items-start justify-content-center" style={{width:"100%"}}>
            {albums?.map((data, index)=>(
                <div key={index} className='m-3' onClick={()=>selectAlbum(data)}>
                    <AlbumCard album={data} />
                </div>
            ))}
        </div>
        {albumsCount > 12 ?
            <div className="d-flex flex-row align-items-center justify-content-center mt-4 mb-4">
                <ReactPaginate
                    pageCount={totalPages}
                    pageRangeDisplayed={5}
                    marginPagesDisplayed={2}
                    forcePage={(albumPageCount-1)}
                    onPageChange={loadAlbumPagination}
                    breakLabel={"..."}
                    nextLabel={"NEXT >"}
                    previousLabel={"< PREVIOUS"}
                    renderOnZeroPageCount={null}
                    className={"pagination-container"}
                    pageClassName={"pagination-page"}
                    pageLinkClassName={"pagination-link"}
                    activeClassName={"pagination-page-active"}
                    activeLinkClassName={"pagination-link-active"}
                    previousClassName={"pagination-previous-next-container"}
                    nextClassName={"pagination-previous-next-container"}
                    previousLinkClassName={"pagination-previous-next-link"}
                    nextLinkClassName={"pagination-previous-next-link"}
                    disabledClassName={"pagination-previous-next-disable"}
                />
            </div>
        :''}
        </>
    )
}

export default GalleryAlbum