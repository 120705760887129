import React, { useState } from 'react'

const AlbumCard = ({album}) => {
    const [isMouseEnter, setIsMouseEnter] = useState(false)
  return (
    <div className="position-relative mt-5 rounded-3" onMouseEnter={()=>setIsMouseEnter(true)} onMouseLeave={()=>setIsMouseEnter(false)} style={{width:'320px'}}>
        <div className=" border border-black position-absolute rounded-3 top-0 start-0 bottom-0 end-0" style={{  backgroundColor:'white', transform:isMouseEnter ?"rotate(10deg)":'rotate(0deg)',  transitionDelay:'100ms', transitionTimingFunction: 'ease-in-out',borderSize:"2px"}}></div>
        <div className=" border border-black position-absolute rounded-3 top-0 start-0 bottom-0 end-0" style={{  backgroundColor:'white', transform:isMouseEnter ?"rotate(5deg)":'rotate(0deg)', transitionDelay:'200ms', transitionTimingFunction: 'ease-in-out',borderSize:"2px"}}></div>
        <div className=" border border-black rounded-3 p-3 position-relative" style={{ backgroundColor:'white'}}>
            <img src={album?.thumbnailImage?.thumbnailStorageName ? `/${album?.thumbnailImage?.thumbnailStorageName}` :`/${album?.images[0]?.thumbnailStorageName}`} alt='' className="rounded-3"style={{width:'288px',height:'200px', objectFit:'cover'}}/>
            <div className="mt-3 d-flex flex-row align-items-center  justify-content-between">
                <div className="d-flex flex-column">
                  <p className="m-0 text-wrap">{album.albumName}</p>
                  <p>{album?.date?.split('T')[0]}</p>
                </div>
                <img src="/assets/icons/album-icon.svg" alt="" style={{width:'25px'}}/>
            </div>
        </div>
    </div>
  )
}

export default AlbumCard