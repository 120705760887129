/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../Context/AppContext'
import { useNavigate } from 'react-router-dom'
// import { useNavigate } from 'react-router-dom';
// import Cookies from 'universal-cookie';

function ProgramsPopup({visible, data, close}) {

    const {selectedPrograms=[], dispatchEvent} =  useContext(AppContext)

    const [isCriteria, setIsCriteria] = useState(false)
    const [isAttachments, setIsAttachments] = useState(false)
    const [isVideosLinks, setIsVideosLinks] = useState(false)
    const [isProgramSelected, setIsProgramSelected] = useState(false)

    const navigate = useNavigate();

    useEffect(()=>{
        if(data?.minimumCriteria && Array.isArray(data.minimumCriteria) && data.minimumCriteria.length){
            setIsCriteria(true)
        }else{
            setIsCriteria(false)
        }
        if(data?.attachments && Array.isArray(data.attachments) && data.attachments.length){
            setIsAttachments(true)
        }else{
            setIsAttachments(false)
        }
        if(data?.links && Array.isArray(data.links) && data.links.length){
            setIsVideosLinks(true)
        }else{
            setIsVideosLinks(false)
        }
        let answer = false
        selectedPrograms.map((index)=>{
            if(index === data.code){
                answer = true
            }
        })
        setIsProgramSelected(answer)
    },[data,visible,selectedPrograms])


    const  applyProgram = (code)=>{
        dispatchEvent("ADD_SELECTED_PROGRAMS", code)
        navigate(`/programs`)
        closeBox()
    }
    const closeBox = ()=>{
        setIsVideosLinks(false);
        setIsAttachments(false)
        setIsCriteria(false)
        close(false)
    }
  return (
    <>
        <div className="program-modal-blur-background" style={{display: visible ? 'block': 'none'}}></div>
        <div className="program-modal"  style={{display: visible ? 'block': 'none'}}>
            <div className="program-modal-content">
                <span className="program-close-button" onClick={()=>closeBox(false)}>×</span>
                <h2 id="program-modal-title">{data.topic}</h2>
                <div className="d-flex flex-column">
                    {data.isDuration ?
                        <div>
                            <p className="d-flex flex-row align-items-center ">
                                <img src="/assets/icons/duration-time-icon.svg" alt="" className="program-card-icon me-2"/>
                                <span  className='fw-bold program-read-more-sub-titles'>Duration:</span> 
                                <span id="program-modal-desc">{" "+data.durationTime +' '+data.durationType}</span>
                            </p>
                        </div> 
                    :''}
                    {data.isCertificate ? 
                        <p className="d-flex flex-row align-items-center ">
                            <img src="/assets/icons/certificate-icon.svg" alt="" className="program-card-icon me-2"/>
                            <span className="fw-bold program-read-more-sub-titles">Certification: </span>
                            <span id="program-modal-desc">{" "+data.certification}</span>
                        </p>
                    : 
                        ''
                    }
                </div>
                <p id="program-modal-desc">{data.desc}</p>
                {isCriteria ? 
                    <div>
                        <p className='fw-bold' id="program-modal-desc">Minimum Criteria</p> 
                        <ul>
                            {data?.minimumCriteria?.map(index=>(
                                <li style={{fontSize:'18px'}} key={index}>{index}</li>
                            ))}
                        </ul>
                    </div>
                :''}
                
                {isVideosLinks ? 
                <div className="mb-4">
                    <p className="d-flex flex-row align-items-center ">
                        <img src="/assets/icons/video-icon.svg" alt="" className="program-card-icon me-2"/>
                        <span className="fw-bold program-read-more-sub-titles">Watch Videos</span>
                    </p>
                    {data.links?.map((data,index)=>(
                        <div key={index}>
                            <a href={data} target='_blank' rel="noreferrer" className='mb-3' id="program-modal-desc">{`Click here for watch video ${index+1}`}</a>
                            <br/>
                        </div>
                    ))}
                </div>
                : ''}
                {isAttachments ? 
                <div>
                    <p className="fw-bold">
                        <img src="/assets/icons/attachment-icon.svg" alt="" className="program-card-icon me-2"/>
                        <span className="program-read-more-sub-titles" id="program-modal-desc">Attachments</span>
                    </p>
                    {data?.attachments?.map((data,index)=>(
                        <p key={index} className="d-flex flex-row algn-items-center">
                            <span id="program-modal-desc">{data?.originalName}</span>
                            <a href={`/${data?.storageName}`} download className='attachments-download-btn'> 
                                <img src="/assets/icons/download-icon.svg" alt="" className="program-card-icon"/>
                            </a>
                        </p>
                    ))}
                </div>
                : ''}
                <button id="program-apply-button" onClick={()=>applyProgram(data.code)} disabled={isProgramSelected } style={{opacity: isProgramSelected ? '0.5': '1'}} className='align-self-center'>Add to Enquiry List</button>
                <p className='align-self-end mb-0 mt-5'  style={{ fontSize:'10px'}}><span className="fw-bold">Last Updated: </span>{" "+data.updatedAt?.split('T')[0]}</p>
            </div>
        </div>
    </>
  )
}

export default ProgramsPopup