/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Heading from './Heading'
import ReactPaginate from 'react-paginate'
import AlbumAddForm from './AlbumAddForm'
import AlbumUpdateForm from './AlbumUpdateForm'
import AlbumDelete from './AlbumDelete'
import GalleryShow from './GalleryShow'
import { AppContext } from '../Context/AppContext'

const GalleryManage = () => {

    const { albums=[], dispatchEvent , albumsCount=0} = useContext(AppContext)

    // const [allAlbums, setAllAlbums] = useState([])
    const [isGalleryPreview, setIsGalleryPreview] = useState(false)
    const [isGalleryAdd, setIsGalleryAdd] = useState(false)
    const [isGalleryUpdate, setIsGalleryUpdate] = useState(false)
    const [updateAlbumData, setUpdateAlbumData] = useState([])
    const [deleteAlbumData, setDeleteAlbumData] = useState([])
    const [deleteAlbumVisible, setDeleteAlbumVisible] = useState(false)
    const [albumPageCount, setAlbumPageCount] = useState(1)
    const [totalPages, setTotalPages] = useState(1)

    useEffect(()=>{
        dispatchEvent('GET_ALBUMS',{page:albumPageCount, limit:7})
        setTimeout(()=>{
            dispatchEvent('CHECK_PAGE_HEIGHT')
        },100)
    },[])

    useEffect(()=>{
        albumsCount%7 !== 0 ? setTotalPages(Math.floor(albumsCount/7)+1) : setTotalPages(Math.floor(albumsCount/7))
    },[albumsCount,albums,isGalleryPreview])

    const handlePreviewGallery = (data) => {
        setIsGalleryPreview(!isGalleryPreview)
    }
    const handleAddAlbum = () => {
        setIsGalleryAdd(!isGalleryAdd)
    }
    const handleUpdateAlbum = (data) => {
        setUpdateAlbumData(data)
        setIsGalleryUpdate(!isGalleryUpdate)
    }
    const handleDeleteAlbum = (data) => {
        setDeleteAlbumVisible(true)
        setDeleteAlbumData(data)
    }
    const loadAlbumPagination = (pageCont) => {
        const page = pageCont.selected+1
        setAlbumPageCount(page)
        dispatchEvent("GET_ALBUMS",{page:page, limit:7})
    }
    return isGalleryPreview ? (
         !isGalleryAdd && !isGalleryUpdate ? 
        <>
            <div className="main-component-body d-flex flex-column align-items-center">
                <Heading>UPDATE GALLERY</Heading>
                <div className="programmes-edit-container">
                    <button className="edit-buttons preview-buttons" onClick={()=>handlePreviewGallery()}>PREVIEW</button>
                    <div className="programmes-edit-top-menu">
                        <p className="programmes-edit-top-menu-text">Total Albums {albumsCount}</p>
                        <button className="edit-buttons add-buttons" onClick={()=>handleAddAlbum()}>ADD NEW +</button>
                    </div>
                    <div className="programmes-table-scroll-view">
                        <table className="table table-light table-striped table-hover" style={{position: 'relative'}}>
                            <thead style={{position:"sticky",top:"0"}}>
                                <tr>
                                    <th className='text-center'>#</th>
                                    <th className='text-center'>Thumbnail</th>
                                    <th>Album Name</th>
                                    <th>Date</th>
                                    <th className="text-center">Action</th>
                                </tr>
                            </thead><tbody className="table-group-divider table-body-responsive">
                                {albums.length !== 0 ? albums?.map((data,index)=>(
                                    <tr key={index}>
                                        <td className='table-data-container text-center'>{index+1+(albumPageCount-1)*7}</td>
                                        <td  className='table-data-container'>
                                            <div className='d-flex align-items-center justify-content-center gallery-thumbnails'>
                                                <img src={data?.thumbnailImage?.thumbnailStorageName ? `/${data?.thumbnailImage?.thumbnailStorageName}` :`/${data?.images[0]?.storageName}`} alt='' className="gallery-pictures"/>
                                            </div>
                                        </td>
                                        <td className='table-data-container'>{data?.albumName}</td>
                                        <td className='table-data-container'>{data?.date?.split('T')[0]}</td>
                                        <td className='table-data-container' style={{width:'30%'}}>
                                            <div className="table-data-button-box">
                                                <button className="edit-buttons update-buttons" onClick={()=>handleUpdateAlbum(data)}>PREVIEW / EDIT</button>
                                                <button className="edit-buttons delete-buttons" onClick={()=>handleDeleteAlbum(data)}>DELETE</button>
                                            </div>
                                        </td>
                                    </tr>
                                )):
                                    <tr>
                                        <td  colSpan="6" className='table-data-container'>
                                            <h1 style={{textAlign: 'center',color:'var(--color-text-on-gray-light)'}}>Add New Album</h1>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                    </div>
                    {albumsCount > 7 ?
                    <div className="d-flex flex-row align-items-center justify-content-center mt-4 mb-4">
                        <ReactPaginate
                            pageCount={totalPages}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={2}
                            forcePage={(albumPageCount-1)}
                            onPageChange={loadAlbumPagination}
                            breakLabel={"..."}
                            nextLabel={"NEXT >"}
                            previousLabel={"< PREVIOUS"}
                            renderOnZeroPageCount={null}
                            className={"pagination-container"}
                            pageClassName={"pagination-page"}
                            pageLinkClassName={"pagination-link"}
                            activeClassName={"pagination-page-active"}
                            activeLinkClassName={"pagination-link-active"}
                            previousClassName={"pagination-previous-next-container"}
                            nextClassName={"pagination-previous-next-container"}
                            previousLinkClassName={"pagination-previous-next-link"}
                            nextLinkClassName={"pagination-previous-next-link"}
                            disabledClassName={"pagination-previous-next-disable"}
                        />
                    </div>:''}
                </div>
                <AlbumDelete 
                    visible={deleteAlbumVisible}
                    close={setDeleteAlbumVisible}
                    deleteAlbumData={deleteAlbumData}
                />
            </div>
        </>:
        <>
            {isGalleryAdd ? <AlbumAddForm close={handleAddAlbum}/>:''}
            {isGalleryUpdate ? <AlbumUpdateForm close={handleUpdateAlbum} data={updateAlbumData}/>:''}
        </>
    ): <GalleryShow close={handlePreviewGallery}/>
}

export default GalleryManage