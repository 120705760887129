import React, { useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { AppContext } from '../Context/AppContext'

const SocialMediaHeader = () => {
  const { loginUser, dispatchEvent } = useContext(AppContext)
  const navigate = useNavigate();
  const handleLogout = () => {
    dispatchEvent('REMOVE_LOGIN_USER_TO_COOKIES')
    dispatchEvent('IS_CHECK_EDIT_PROGRAM_PAGE', false)
    navigate('/')
  }
  return (
    <div className="social-media-container py-3">
      <div className="d-flex flex-row">
        <a href="https://www.facebook.com/profile.php?id=61559903737398" target='_blank' className="d-flex flex-row align-items-center text-decoration-none fw-bold" rel="noreferrer">
          <img src="/assets/icons/social-media/social-media-icon-facebook-white.svg" alt="" className="social-media-image"/>
          <p className='social-media-text social-media-text-none'>Facebook</p>
        </a>
        <a href="https://www.youtube.com/@ChamberSkillsSdnBhd-ui2ft" target='_blank' className="d-flex flex-row align-items-center mx-2 text-decoration-none fw-bold" rel="noreferrer">
          <img src="/assets/icons/social-media/social-media-icon-youtube-white.svg" alt="" className="social-media-image"/>
          <p className='social-media-text social-media-text-none'>YouTube</p>
        </a>
        <a href="https://www.linkedin.com/company/102744845/" target='_blank' className="d-flex flex-row align-items-center  text-decoration-none fw-bold" rel="noreferrer">
          <img src="/assets/icons/social-media/social-media-icon-linkedin-white.svg" alt="" className="social-media-image"/>
          <p className='social-media-text social-media-text-none'>Linkedin</p>
        </a>
      </div>
      <div className="d-flex flex-row">
        <div className="d-flex flex-row align-items-center me-1 me-sm-3">
          <div className='d-flex flex-row align-items-center  text-decoration-none fw-bold'>
            <img src="/assets/icons/contact/contact-icon-location-white.svg" alt="" className="social-media-image"/>
            <p className='social-media-text'>Kuala Lumpur, Malaysia</p>
          </div>
          <p className='social-media-text fw-bold mx-1 mx-sm-2'>+603-22763253</p>
        </div>  
        { loginUser ? 
          <button onClick={()=>handleLogout()} className='d-flex justify-content-end logout-btn px-0'>
            <img src="/assets/icons/login-logout/logout.svg" alt="" className="social-media-image"/>
          </button> :
          <Link to="/login" className='d-flex justify-content-end'>
            <img src="/assets/icons/login-logout/login.svg" alt="" className="social-media-image"/>
          </Link>
        }
      </div>
    </div>
  )
}

export default SocialMediaHeader