import React from 'react'
import { SubHeadingForme } from './Heading'

const ImageDelete = ({visible, close, deleteImage, callDelete}) => {
    const closeDeleteRequest = () => {
        close(false)
    }
    const handleDeleteImage = () => {
        callDelete(true, deleteImage.index)
        closeDeleteRequest()
    }
  return (
    <div style={{display: visible ? 'block': 'none'}}>
            <div className="program-modal-blur-background" style={{display: 'block',zIndex:'1'}}></div>
            <div className="program-modal d-flex flex-column align-items-center justify-content-center">
                <div className="program-popup-box">
                    <SubHeadingForme>DELETE IMAGE</SubHeadingForme>
                    <p  style={{textAlign:"center",color:"black"}}  className="program-popup-massage">Are you sure you want to delete ?<br/><img src={`${ deleteImage?.storageName ? "/"+deleteImage?.storageName : ''}`} alt='' style={{width:'200px'}}/></p>
                    <div className="d-flex flex-row justify-content-around" style={{width:"100%"}}>
                        <button type='button'  className="edit-buttons close-buttons"onClick={()=>closeDeleteRequest()}>No</button>
                        <button type='button'  className="edit-buttons delete-buttons" onClick={()=>handleDeleteImage()}>YES</button>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ImageDelete