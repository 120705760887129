/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { AppContext } from '../Context/AppContext';
const keyWords = require('../Static-Data/programs-key-words.json')

function Search() {

    const { dispatchEvent, isCheckEditProgramPage } = useContext(AppContext)

    const [searchValue, setSearchValue] = useState({topic:''})
    const [showBox, setShowBox] = useState(false)
    const [isProgramManagePage, setIsProgramManagePage] = useState(false)
    const [searchingValues, setSearchingValues] = useState([])

    const navigate = useNavigate();
    const page = useLocation()
    const pathCode = window.location.pathname.split('/',3);

    useEffect(()=>{
        if(page.pathname !== "/enquiry"){
            let value =[]
            keyWords?.filter(item => {
                if(!searchValue.topic){return false}
                return Object.values(item)
                .join('')
                ?.toLowerCase()
                ?.includes(searchValue?.topic?.toLowerCase())
            })
            ?.map((item)=>(
                value.push(item)
            ))
            let sorted = value.sort((a, b) => a[0].localeCompare(b[0]))
            setSearchingValues(sorted)
        }else{
            let value =[]
            keyWords?.filter(item => {
                if(!searchValue.topic){return false}
                return Object.values(item)
                .join('')
                ?.toLowerCase()
                ?.includes(searchValue?.topic?.toLowerCase())
            })
            ?.map((item)=>(
                value.push(item)
            ))
            let sorted = value.sort((a, b) => a[0].localeCompare(b[0]))
            setSearchingValues(sorted)
            dispatchEvent("SEARCH_PERMANENT_VALUE",searchValue?.topic)
        }
    },[searchValue])
    
    useEffect(()=>{
        setSearchValue({topic:''})
    },[searchValue.topic === undefined])

    useEffect(()=>{
        searchDisable()
    },[page,isCheckEditProgramPage])

    function handleChange (e) {
        const value = e.target.value
        !value ? setShowBox(false) : setShowBox(true)
        setSearchValue({topic:value})
    }
    function handleSearch(e){
        e.preventDefault()
        if(page.pathname !== "/enquiry"){
            dispatchEvent("SEARCH_VALUE",searchValue.topic)
            dispatchEvent("GET_ALL_PROGRAMS",`${searchValue.topic}`)
            dispatchEvent("GET_ALL_PROGRAMS_BY_CONDITION",{page:1, limit:12, sortOrder:`ASC`,sortBy:`_id`,search:`${searchValue.topic}`})
            setShowBox(false)
            navigate(`/programs`)
        }else{
            dispatchEvent("SEARCH_PERMANENT_VALUE",searchValue?.topic)
        }
    }
    function handleFillKeyWord(key){
        setSearchValue({topic:key})
        setShowBox(false)
    }
    const searchDisable =()=>{
        if(isCheckEditProgramPage && (page.pathname === "/programs" || page.pathname === `/programs/${pathCode[2]}`)){
            setIsProgramManagePage(true)
        }
        else{
            setIsProgramManagePage(false)
        }
    }
  return (
    <div className=' d-flex flex-column search-component-box'>
        <form className="nav-bar-search-form col-md-3" onSubmit={(e)=>handleSearch(e)}>
            <input 
                type="text" 
                name="search" 
                placeholder="Search" 
                value={searchValue.topic}
                className="nav-bar-search-box py-3"
                disabled={isProgramManagePage }
                onChange={(e)=>handleChange(e)}
            />
            <button type="submit" className="nav-bar-search-btn py-3" disabled={isProgramManagePage}><img src="/assets/icons/search-icon-red.svg" alt='' className="nav-bar-search-btn-img" /></button>
        </form>
        <div className='show-item-box'>
            <div className="show-item-section" style={{display: showBox ? 'block': 'none'}}>
                { searchingValues.map((item)=>(
                    <p className="search-value-box" key={item} onClick={()=>handleFillKeyWord(item)}>{item}</p>
                ))}
            </div>
        </div>
    </div>
  )
}

export default Search