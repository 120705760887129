import { apiClient } from "./ApiService";
import {userToken} from "../Util/userToken"

export const postAlbum = async (data) => {
    const JWTTOKEN = await userToken()
    return await apiClient.post('/api/v1/gallery',data,{headers:{"access-token":JWTTOKEN}});
}

export const getAllAlbums = async (page=1, limit=12,) => {
    return await apiClient.get(`/api/v1/gallery?page=${page}&limit=${limit}`);
}

export const getAlbumsCount = async () => {
    return await apiClient.get('/api/v1/gallery/albumsCount')
}

export const updateAlbum = async (id, data) => {
    const JWTTOKEN = await userToken()
    return await apiClient.put(`/api/v1/gallery/${id}`,data,{headers:{"access-token":JWTTOKEN}});
}

export const deleteAlbum = async (id) => {
    const JWTTOKEN = await userToken()
    return await apiClient.delete(`/api/v1/gallery/${id}`,{headers:{"access-token":JWTTOKEN}});
}