import React, { useContext, useState } from 'react'
import { Link, useLocation} from 'react-router-dom'
import Search from './Search'
import { AppContext } from '../Context/AppContext'

function HeaderNavBar() {

    const {selectedPrograms=[]} =  useContext(AppContext)

    const [menuTrigger, setMenuTrigger] = useState(false)

    const path = useLocation()
    const pathTwo = window.location.pathname.split('/',3);

    const menuHandler = (action)=>{
        action ? setMenuTrigger(true): setMenuTrigger(false)
    }
  return (
    <nav className="nav-bar col-md-12 px-2 ">
        <div className="nav-bar-logo-box">
            <Link to="/" className="nav-bar-logo-box-container">
                <img src="./assets/logo/chamber-skills-logo.svg"alt='' className="nav-bar-logo"/>
                <p className="nav-bar-logo-text">CHAMBER SKILLS</p>
            </Link>
            <img src="/assets/icons/menu.svg" alt='' className={`nav-bar-menu ${menuTrigger ? 'nav-bar-menu-trigger': ''}`} onClick={()=>menuHandler(true)}/>
        </div>
        <ul className={`nav-bar-nav-section ${menuTrigger ? 'nav-bar-nav-section-trigger': ''}`} >
            <li className="nav-bar-nav-section-close" id="close" onClick={()=>menuHandler(false)}><img src="/assets/icons/close.svg" alt="CLOSE" className="nav-menu-close-btn"/></li>
            <li className={`nav-bar-item ${path.pathname === '/'? 'nav-bar-item-active':''}`} id="home"><Link to="/" className="nav-bar-link">Home</Link></li>
            <li className={`nav-bar-item ${path.pathname === '/about-us'? 'nav-bar-item-active':''}`} id="about-us"><Link to="/about-us" className="nav-bar-link">About Us</Link></li>
            <li className={`nav-bar-item ${path.pathname === `/programs/${pathTwo[2]}` ? 'nav-bar-item-active':''} ${path.pathname === '/programs'? 'nav-bar-item-active':''}` } id="programmes"><Link to="/programs" className="nav-bar-link">Programs</Link></li>
            <li className={`nav-bar-item ${path.pathname === '/gallery'? 'nav-bar-item-active':''}`} id="gallery"><Link to="/gallery" className="nav-bar-link">Gallery</Link></li>
            <li className={`nav-bar-item ${path.pathname === `/enquiry/${pathTwo[2]}` ? 'nav-bar-item-active':''} ${path.pathname === '/enquiry'? 'nav-bar-item-active':''}` } id="contact-us"><Link to="/enquiry" className="nav-bar-link">Enquiry{ selectedPrograms?.length !==0 ?<span className="enquiry-programs-count">{selectedPrograms?.length}</span>:''}</Link></li>
        </ul>
        <Search/>
    </nav>
  )
}

export default HeaderNavBar
