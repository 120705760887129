/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useRef, useState } from 'react'
import ReactPaginate from 'react-paginate'
import ProgramsPopup from './ProgramsPopup'
import LoadingPage from './LoadingPage'
import Heading from './Heading'
import BackToTop from './BackToTop'
import { AppContext } from '../Context/AppContext'

function ProgramsPage() {


    const { programs=[], allPrograms=[], selectedPrograms=[], loginUser, searchValue, isVisitProgramPage, dispatchEvent, isCheckEditProgramPage,isStaticPrograms } = useContext(AppContext)
    const [isLoading, setIsLoading] = useState(true)

    const [isShowProgramDetailsPopUp, setIsShowProgramDetailsPopUp] = useState(false)
    const [selectedProgramToView, setSelectedProgramToView] = useState({})
    const [pageCount, setPageCount] = useState(1)

    // Program Edit View
    const [loadProgramsCount, setLoadProgramsCount] = useState(6)
    const [totalPages, setTotalPages] = useState(1)
    const [viewMoreButton, setViewMoreButton] = useState(false)

    const [previousSearchValue, setPreviousSearchValue] = useState(isVisitProgramPage.lastSearchValue)
    const lastPosition = useRef()
    const lastProgramsCount = useRef()
    const lastSearchValue = useRef()

    useEffect(()=>{
        if(isVisitProgramPage.status){
            setLoadProgramsCount(isVisitProgramPage.programsCount)
            setPageCount(isVisitProgramPage.lastPage)
            setIsLoading(false)
            dispatchEvent('CHECK_PAGE_HEIGHT')
        }else{
            setTimeout(()=>{
                setIsLoading(false)
                dispatchEvent('CHECK_PAGE_HEIGHT')
            },1000) 
        }  
    },[])

    useEffect(()=>{
        if(searchValue === ''){
            Array.isArray(allPrograms) && allPrograms.length>6 && loadProgramsCount < 12 ? setViewMoreButton(true) : setViewMoreButton(false)
        }
        else{
            loadMorePrograms()
        }
        if(isVisitProgramPage.status && searchValue !== ''){
            if(Array.isArray(allPrograms) && allPrograms.length < 12){ 
                setPageCount(1) 
            }
            else{
                if(previousSearchValue !== searchValue){
                    setPreviousSearchValue(searchValue)
                    setPageCount(1)
                }
                lastSearchValue.current = searchValue
            }
        }
    },[programs])

    useEffect(()=>{
        allPrograms.length%12 !== 0 ? setTotalPages(Math.floor(allPrograms.length/12)+1) : setTotalPages(Math.floor(allPrograms.length/12))
    },[allPrograms])
    
    useEffect(()=>{
        return ()=>{   
            dispatchEvent("IS_VISIT_PROGRAM_PAGE",{status:true, lastPage:lastPosition.current, programsCount:lastProgramsCount.current, lastSearchValue:lastSearchValue.current })
        }
    },[])

    useEffect(()=>{
        if(loadProgramsCount>=12){
            allPrograms.length%12 !== 0 ? setTotalPages(Math.floor(allPrograms.length/12)+1) : setTotalPages(Math.floor(allPrograms.length/12))
            setViewMoreButton(false);
        }
        dispatchEvent('CHECK_PAGE_HEIGHT')
        lastPosition.current = pageCount
        lastProgramsCount.current = loadProgramsCount 
    },[loadProgramsCount,pageCount])

    useEffect(()=>{
        lastPosition.current = pageCount
    },[pageCount,programs.length])

    useEffect(()=>{
        if(isVisitProgramPage.status&& searchValue !== ''){
            dispatchEvent("GET_ALL_PROGRAMS_BY_CONDITION",{page:isVisitProgramPage.lastPage, limit:12, sortOrder:`ASC`,sortBy:`_id`,search:`${searchValue}`})
        }
        else{
            dispatchEvent("GET_ALL_PROGRAMS_BY_CONDITION",{page:1, limit:12, sortOrder:`ASC`,sortBy:`_id`,search:`${searchValue}`})
        }
    },[isCheckEditProgramPage])

    const showProgramDetailsPopUp = () => {
        setIsShowProgramDetailsPopUp(true)
    }

    const closeProgramDetailsPopUp = () => {
        setIsShowProgramDetailsPopUp(false)
    }

    const viewProgramDetails = (program) => {
        showProgramDetailsPopUp();
        setSelectedProgramToView(program)
    }

    const loadMorePrograms = ()=>{
        if(loadProgramsCount<12){
            var length = loadProgramsCount+6;
            lastProgramsCount.current = length
            setLoadProgramsCount(length)
        }
        else{
            setViewMoreButton(false);
        }
    }

    const scrollToTop = ()=> {
        window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        });
    }
    const loadProgramsPages = (pageSide)=>{
        const page = pageSide.selected+1
        setPageCount(page)
        dispatchEvent("GET_ALL_PROGRAMS_BY_CONDITION",{page:page, limit:12, sortOrder:`ASC`,sortBy:`_id`,search:`${searchValue}`})
        scrollToTop()
    }
    
    function truncate(description){
        return  description.length > 170 ? description.substring(0, 150) + "..." : description
    }

    const setTriggerVisiblePreview = () => {        
        dispatchEvent('IS_CHECK_EDIT_PROGRAM_PAGE', true)
    }

    const checkSelected = (code)=>{
        if(code !== undefined){
            const found = selectedPrograms.findLast(indexIn=>(code === indexIn))
            return found
        }
    }
    const identifyDurationType = (time , type)=>{
        const timeDurationTypes = {hours:'hour', days:'day', weeks:'week', months:'month', years:'year'}
        const isTypeExist = timeDurationTypes.hasOwnProperty(type)
        if(time === 1 && isTypeExist){
            return timeDurationTypes[type]
        }
        else{
            return type
        }
    }
  return(
    <section className="programs-container d-flex flex-column align-items-center mb-5">
        <Heading>PROGRAMS</Heading>
            { loginUser ? <div style={{position:"relative", width:"100%", height:"50px"}}>
                <button className="edit-buttons preview-buttons" onClick={()=>setTriggerVisiblePreview()}>Go To Edit</button>
            </div> : ''}
        
            {programs.length !== 0 ? <> <div className="d-flex flex-wrap justify-content-center">
                {programs?.slice(0,6)?.map((program, index) =>(
                    <div key={index} className="program-item mx-3 " style={{borderStyle: isStaticPrograms ? "double" : "solid"}} id={program.code}>
                        {checkSelected(program.code) ?<p className="program-enquiry-mark" ><span className="program-enquiry-mark-content"><span className="program-enquiry-mark-content-item">ENQUIRY</span></span></p>:''}
                        <h4 className="program-title">{program.topic}</h4>
                        <div className="d-flex flex-row my-3">
                            { program.isDuration ?
                                <div className='d-flex flex-row align-items-center'>
                                        <img src="/assets/icons/duration-icon.svg" alt="" className="program-card-icon"/>
                                        <p className='m-0 ms-2 me-2 program-details'>{`${program.durationTime} ${identifyDurationType(program.durationTime, program.durationType)}`}</p>
                                </div>
                            :''}
                            { program.isCertificate ?
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='m-0 me-2 program-details fw-bold'>CERTIFICATE</p>
                                    <img src="/assets/icons/citification-tick.svg" alt="" className="program-card-icon"/>
                                </div>
                            : ''}
                        </div>
                        <p className="program-details">{truncate(program.desc)}</p>
                        <button className="program-read-more" style={{opacity:isLoading ? "50%": "100%"}} disabled={isLoading ? true : false} onClick={()=>viewProgramDetails(program)}>Read More</button>
                    </div>
                ))}
            </div>
        <div className="d-flex flex-wrap justify-content-center">
        {isLoading ? <LoadingPage/> : programs?.slice(6,loadProgramsCount)?.map((program, index) =>(                
                <div key={index} className="program-item mx-3 " style={{border: isStaticPrograms ? '4px double' : 'solid', borderTopLeftRadius: isStaticPrograms ? '0':''}} id={program.code}>
                    {checkSelected(program.code) ?<p className="program-enquiry-mark" ><span className="program-enquiry-mark-content"><span className="program-enquiry-mark-content-item">ENQUIRY</span></span></p>:''}
                    <h4 className="program-title">{program.topic}</h4>
                    <div className="d-flex flex-row my-3">
                        { program.isDuration ?
                            <div className='d-flex flex-row align-items-center'>
                                    <img src="/assets/icons/duration-icon.svg" alt="" className="program-card-icon"/>
                                    <p className='m-0 ms-2 me-2 program-details'>{`${program.durationTime} ${identifyDurationType(program.durationTime, program.durationType)}`}</p>
                            </div>
                        :''}
                        { program.isCertificate ?
                            <div className='d-flex flex-row align-items-center'>
                                <p className='m-0 me-2 program-details fw-bold'>CERTIFICATE</p>
                                <img src="/assets/icons/citification-tick.svg" alt="" className="program-card-icon"/>
                            </div>
                        : ''}
                    </div>
                    <p className="program-details">{truncate(program.desc)}</p>
                    <button className="program-read-more" onClick={()=> viewProgramDetails(program)}>Read More</button>
                </div>
            ))}
        </div>
        
        </>
        :
        <h1 className='mt-5 pt-5 fw-bold' style={{color:'var(--color-text-on-gray-light)'}}>Programs Not Found</h1>}
        <button id="view-more-programs-btn" style={{display:viewMoreButton? 'block': 'none', opacity:isLoading ? "50%": "100%"}} disabled={isLoading? true : false} onClick={()=>loadMorePrograms()}>View More</button>
       {Array.isArray(allPrograms) && allPrograms.length > 12 ? <div style={{display:!viewMoreButton? 'flex': 'none'}}>
            <ReactPaginate 
                pageCount={totalPages}
                pageRangeDisplayed={5}
                marginPagesDisplayed={3}
                forcePage={(pageCount-1)}
                onPageChange={loadProgramsPages}
                breakLabel={"..."}
                nextLabel={"NEXT >"}
                previousLabel={"< PREVIOUS"}
                renderOnZeroPageCount={null}
                className={"pagination-container"}
                pageClassName={"pagination-page"}
                pageLinkClassName={"pagination-link"}
                activeClassName={"pagination-page-active"}
                activeLinkClassName={"pagination-link-active"}
                previousClassName={"pagination-previous-next-container"}
                nextClassName={"pagination-previous-next-container"}
                previousLinkClassName={"pagination-previous-next-link"}
                nextLinkClassName={"pagination-previous-next-link"}
                disabledClassName={"pagination-previous-next-disable"}
            />
        </div>: "" }
        <ProgramsPopup visible={isShowProgramDetailsPopUp} close={closeProgramDetailsPopUp} data={selectedProgramToView} />
        <BackToTop/>
    </section>
  )
}

export default ProgramsPage