import React from 'react'
import WelcomeSection from '../Components/WelcomeSection'
import AboutUsIndex from '../Components/AboutUsIndex'
import BackToTop from '../Components/BackToTop'
import ProgramsIndex from '../Components/ProgramsIndex'
import ContactUs from './ContactUs'
function Index() {
  return (
    <>
      <WelcomeSection/>
      <AboutUsIndex/>
      <ProgramsIndex/>
      <ContactUs/>
      <BackToTop/>
    </>
  )
}

export default Index