import { apiClient } from "./ApiService";
import {userToken} from "../Util/userToken"

export const fetchPrograms = async (page=1, limit=12, sortOrder='ASC', sortBy='_id', search='') => {
    return await apiClient.get(`/api/v1/programs?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}`)
}
export const fetchProgramsWithToken = async (page=1, limit=12, sortOrder='ASC', sortBy='_id', search='') => {
    const JWTTOKEN = await userToken()
    return await apiClient.get(`/api/v1/programs?page=${page}&limit=${limit}&sortOrder=${sortOrder}&sortBy=${sortBy}&search=${search}`,{headers:{"access-token":JWTTOKEN}})
}
export const getAllProgramsCount = async () => {
    return await apiClient.get(`/api/v1/programs/programsCount`)
}
export const postPrograms = async (data) => {
    const JWTTOKEN = await userToken()
    return await apiClient.post(`/api/v1/programs`,data,{headers:{"access-token":JWTTOKEN}})
}
export const updatePrograms = async (id, updateProgram) => {
    const JWTTOKEN = await userToken()
    return await apiClient.put(`/api/v1/programs/${id}`,updateProgram,{headers:{"access-token":JWTTOKEN}});
}
export const deletePrograms = async (id) => {
    const JWTTOKEN = await userToken()
    return await apiClient.delete(`/api/v1/programs/${id}`,{headers:{"access-token":JWTTOKEN}});
}
