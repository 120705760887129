import React from 'react'

function WelcomeSection() {
    return (
        <section className="main-section">
          <div className="main-section-slideshow">
          <img src="./assets/images/wallpapers/main-home-page-wallpaper-01.png" alt="" className="main-section-img" name="mainSectionImg"/>
          <img src="./assets/images/wallpapers/main-home-page-wallpaper-02.png" alt="" className="main-section-img" name="mainSectionImg"/>
          <img src="./assets/images/wallpapers/main-home-page-wallpaper-01.png" alt="" className="main-section-img" name="mainSectionImg"/>
          </div>
        </section>
    )
}

export default WelcomeSection 