/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useContext, useEffect, useState } from 'react'
import Heading, {SubHeading} from '../Components/Heading'
import BackToTop from '../Components/BackToTop'
import { AppContext } from '../Context/AppContext';
const aboutUsContent = require('../Static-Data/about-us-content.json');

function AboutUs() {
  
  const { dispatchEvent } = useContext(AppContext)

    const [whoWeAreTitle, setWhoWeAreTitle]= useState("")
    const [whoWeAreDescribing, setWhoWeAreDescribing]= useState([])
    const [vision , setVision ]= useState({title:'',description:''})
    const [mission , setMission ]= useState({title:'',description:''})
    const [aboutUSImage , setAboutUSImage ]= useState("")
    const [visionMissionImage , setVisionMissionImage ]= useState("")

    useEffect(()=>{
      setWhoWeAreTitle(aboutUsContent?.aboutUsText[0]?.title)
      setWhoWeAreDescribing(aboutUsContent?.aboutUsText[0]?.description)
      setVision({title: aboutUsContent?.aboutUsText[1]?.title, description: aboutUsContent?.aboutUsText[1]?.description})
      setMission({title: aboutUsContent?.aboutUsText[2]?.title, description: aboutUsContent?.aboutUsText[2]?.description})
      setAboutUSImage(aboutUsContent?.aboutUsImages[0]?.image)
      setVisionMissionImage(aboutUsContent?.aboutUsImages[1]?.image)
    },[])
    useEffect(()=>{
        setTimeout(() => {
          dispatchEvent("CHECK_PAGE_HEIGHT")
        }, 100);
    },[visionMissionImage, aboutUSImage])
  return (
    <section className="about-us-content-bar align-items-center">
      <Heading>ABOUT US</Heading> 
        <SubHeading>A Few Words About Us</SubHeading>
      <div className="about-us-first-box">
        <div className="about-us-image-box">
            <img src= {`./assets/images/wallpapers/${aboutUSImage}`} alt="content-image"/>
        </div>
        <div className="about-us-para">
            <h1>{whoWeAreTitle}</h1>
            {whoWeAreDescribing?.map((index) => (
                <p key={index}>
                {index}
            </p>
            ))}
        </div>
      </div>
      <div className="about-us-second-box">
        <div className="about-us-image-box">
            <img src={`./assets/images/wallpapers/${visionMissionImage}`} alt="content-image"/>
        </div>
        <div className="about-us-para">
            <h1>{vision.title}</h1>
            <p>
            {vision.description}
            </p>
            <h1>{mission.title}</h1>
            <p>
            {mission.description}
            </p>
        </div>
      </div>
      <BackToTop/>
  </section>
  )
}

export default AboutUs