import React from 'react'
import { Link } from 'react-router-dom'

function NotFoundError() {
  return (
    <div className='error-container'>
        <div className='banner'>
            <div className="image-box">
                <img src="/assets/images/404.svg" alt="My Happy SVG"/>
            </div>
            <p>Sorry, we unable to find that page</p>
            <p>Go Back to <Link to="/" className='customlink'>Home</Link> </p>
        </div>
    </div>
  )
}

export default NotFoundError