import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./css/social-media-header.css"
import "./css/header.css"
import "./css/footer.css"
import "./css/search.css"
import "./css/style.css"
import "./css/home.css"
import "./css/programmes.css"
import "./css/about-us.css"
import "./css/gallery.css"
import "./css/contact-us.css"
import "./css/error.css"
import "./css/loading.css"
import "./css/login.css"
import "./css/announcement.css"
import 'react-phone-number-input/style.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
