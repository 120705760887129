/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import './App.css';
import HeaderNavBar from './Components/HeaderNavBar';
import SocialMediaHeader from './Components/SocialMediaHeader';
import Footer from './Components/Footer';
import Index from './Screens/Index';
import Programs from './Screens/Programs';
import AboutUs from './Screens/AboutUs';
import Gallery from './Screens/Gallery';
import ContactUs from './Screens/ContactUs';
import NotFoundError from './Screens/NotFoundError';
import Login from './Screens/Login';
import { AppContext } from './Context/AppContext';
import LoadingMain from './Components/LoadingMain';
import Cookies from 'universal-cookie';
import { fetchPrograms, fetchProgramsWithToken, getAllProgramsCount } from './APIS/ProgramsApi';
import { getAlbumsCount, getAllAlbums } from './APIS/GalleryApi'
import staticPrograms from './Static-Data/programs.json';

const App = () => {
  
  const [elementHeight, setElementHeight] = useState(false)
  
  const [loginUser, setLoginUser] = useState()
  const [programs, setPrograms] = useState([])
  const [allPrograms, setAllPrograms] = useState([])
  const [allProgramsCount, setAllProgramsCount] = useState(0)
  const [permanentAllPrograms, setPermanentAllPrograms] = useState([])
  const [filteringPermanentAllPrograms, setFilteringPermanentAllProgram] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const [selectedPrograms, setSelectedPrograms] = useState([])
  const [albums, setAlbums] = useState([])
  const [images, setImages] = useState([])
  const [albumsCount, setAlbumsCount] = useState(0)

  const [isStaticPrograms, setIsStaticPrograms] = useState(true)
  const [isLoading, setIsLoading] = useState(true)
  const page = useLocation()
  
  const [isCheckEditProgramPage,setIsCheckEditProgramPage] = useState(false)
  const [isVisitProgramPage, setIsVisitProgramPage] = useState(false)

  useEffect(()=>{

    setPrograms(staticPrograms)
    setAllPrograms(staticPrograms)
    dispatchEvent('GET_ALL_PROGRAMS')
    dispatchEvent('GET_ALL_PROGRAMS_BY_CONDITION')
    dispatchEvent('SET_LOGIN_USER_FROM_COOKIES')
    dispatchEvent('GET_SELECTED_PROGRAMS')
    isCheckPageHeightForFooterState()
    setLoading()
    
  },[])
  useEffect(()=>{
      if(programs?.length === 0 && searchValue === ''){
        setPrograms(staticPrograms)
        setAllPrograms(staticPrograms)
        setIsStaticPrograms(true)
      }
    dispatchEvent('CHECK_PAGE_HEIGHT')
  },[programs])
  useEffect(()=>{
    setFilteringPermanentAllProgram(permanentAllPrograms)
  },[permanentAllPrograms])


  const fetchAllPrograms = async (search='')=>{
    try{          
      const response = await fetchPrograms(1,100, 'ASC', '_id', search)
      setAllPrograms(response.data)
      if(isStaticPrograms){
        setPermanentAllPrograms(response.data)
      }
      setIsStaticPrograms(false)
    }catch(error){
            
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Program Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
      setIsStaticPrograms(true)
    }
  }

  const fetchAllProgramsByCondition = async (payload)=>{
    try{       
      const response = await fetchPrograms(payload?.page, payload?.limit, payload?.sortOrder, payload?.sortBy, payload?.search)
      setPrograms(response.data)
      setIsStaticPrograms(false)
    }catch(error){
            
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Program Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
      setIsStaticPrograms(true)
    }
  }

  
  const fetchAllProgramsCount = async ()=>{
    try{
      const respond = await getAllProgramsCount()
      setAllProgramsCount(respond.data)
    }
    catch(error){
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Program Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
    }
  }

  const fetchAllProgramsByToken = async (payload) =>{
    try{
      const response = await fetchProgramsWithToken(payload?.page, payload?.limit, payload?.sortOrder, payload?.sortBy, payload?.search)
      setPrograms(response.data)  
    }catch(error){
            
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Program Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
      setIsStaticPrograms(true)
    }
  }
  const setLoginUserFromCookies = () => {
    const cookies = new Cookies();
    const jwtToken = cookies.get('JWT_TOKEN')
    setLoginUser(jwtToken)   
  }
  const removeLoginUserFromCookies = () => {
    const cookies = new Cookies();
    cookies.remove('JWT_TOKEN')
    setLoginUser(undefined) 
  }
  const setLoginUserToCookies = (jwtToken) => {
    const cookies = new Cookies();
    cookies.set('JWT_TOKEN', jwtToken, {maxAge:36000});  
    setLoginUser(jwtToken)   
  }
  const setLoading = ()=>{
    setIsLoading(false)
  }
  useEffect(()=>{
    isCheckPageHeightForFooterState()
  },[isLoading, page.pathname])

  const isCheckPageHeightForFooterState = ()=>{
    const elementHeight = document.querySelector('.main-component-wrapper').clientHeight
    const displayHeight = window.innerHeight
    if(elementHeight > displayHeight) {
      setElementHeight(true)
    }else{
      setElementHeight(false)
    }
  }

  const isSetSearchValue = (value) =>{
    setSearchValue(value)
  }
  const addSelectedPrograms = (newProgram)=>{
    let programList = selectedPrograms
    programList.push(newProgram)
    const cookies = new Cookies();
    cookies.set('SELECTED_PROGRAMS', programList, {maxAge:36000});
    setSelectedPrograms(programList)
  }
  const getSelectedPrograms =()=>{
    const cookies = new Cookies();
    const SELECTED_PROGRAMS = cookies.get('SELECTED_PROGRAMS')
    SELECTED_PROGRAMS 
    ?
      setSelectedPrograms(SELECTED_PROGRAMS)
    :
      setSelectedPrograms([])
  }
  const removeSelectedPrograms = (removeProgram) =>{
    let defaultArray = selectedPrograms
    const index = defaultArray.indexOf(removeProgram)
    defaultArray.splice(index,1)
    const cookies = new Cookies();
    cookies.set('SELECTED_PROGRAMS', defaultArray, {maxAge:36000});
    setSelectedPrograms(defaultArray)
  }
  const cleanSelectedPrograms = ()=>{
    const cookies = new Cookies();
    cookies.remove('SELECTED_PROGRAMS')
    setSelectedPrograms([])
  }
  const searchPermanentAllPrograms=(searchKey)=>{
    if(searchKey){
      let value =[]
      permanentAllPrograms?.filter(item => {
          if(!searchKey){return false}
          return Object.values(item)
          .join('')
          ?.toLowerCase()
          ?.includes(searchKey?.toLowerCase())
      })
      ?.map((item)=>(
          value.push(item)
      ))
      setFilteringPermanentAllProgram(value)
    }else{
      setFilteringPermanentAllProgram(permanentAllPrograms)
    }
  }
  const getAlbums = async (payload)=>{
    try{       
      const response = await getAllAlbums(payload?.page, payload?.limit)
      let getImages =[]
      response.data?.map((data, index)=>(
        data.images.map((image, imageIndex)=>(
          getImages.push(image?.storageName)
        ))
      ))
      setImages(getImages)
      setAlbums([...response.data])
    }catch(error){
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Albums Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
    }
  }
  const getAlbumCount = async () =>{
    try{
      const respond = await getAlbumsCount()
      setAlbumsCount(respond.data)
    }
    catch(error){
      let message = error?.message
      const response = error?.response
      if(response){
          const {status, statusText} = response
          if(response && status === 404){
              message =statusText || `Albums Not Found`
          }
          else if(response && status === 500){
              message =statusText ||`Service Unreachable !`
          }
      }
      console.log(message)
    }
  }
  const dispatchEvent = (action, payload) => {    
    switch (action) {
      case 'REMOVE_LOGIN_USER_TO_COOKIES':
        removeLoginUserFromCookies()
        break;
      case 'SET_LOGIN_USER_TO_COOKIES':
        setLoginUserToCookies(payload)
        break;
      case 'SET_LOGIN_USER_FROM_COOKIES':
        setLoginUserFromCookies()
        break;
      case 'GET_ALL_PROGRAMS':
        fetchAllPrograms(payload)        
        break;  
      case 'GET_ALL_PROGRAMS_BY_CONDITION':      
        fetchAllProgramsByCondition(payload)        
        break;  
      case 'GET_ALL_PROGRAMS_BY_TOKEN':      
        fetchAllProgramsByToken(payload)
        fetchAllProgramsCount()    
        break;
      case 'IS_CHECK_EDIT_PROGRAM_PAGE':        
        setIsCheckEditProgramPage(payload)
        break;   
      case 'IS_VISIT_PROGRAM_PAGE':        
        setIsVisitProgramPage(payload)
        break;
      case 'CHECK_PAGE_HEIGHT':        
        isCheckPageHeightForFooterState()
        break;
      case 'SEARCH_VALUE':        
        isSetSearchValue(payload)
        break;
      case 'ADD_SELECTED_PROGRAMS':        
        addSelectedPrograms(payload)
        break;
      case 'GET_SELECTED_PROGRAMS':        
        getSelectedPrograms(payload)
        break;
      case 'REMOVE_SELECTED_PROGRAMS':        
        removeSelectedPrograms(payload)
        break;
      case 'CLEAN_SELECTED_PROGRAMS':        
        cleanSelectedPrograms(payload)
        break;
      case 'SEARCH_PERMANENT_VALUE':        
        searchPermanentAllPrograms(payload)
        break;
      case 'GET_ALBUMS':        
        getAlbums(payload)
        getAlbumCount()
        break;
      default:
        return;
    }
  }

  return (
    <AppContext.Provider value={{loginUser, programs, allPrograms, allProgramsCount, selectedPrograms, permanentAllPrograms, filteringPermanentAllPrograms, albums, images, albumsCount, searchValue, isCheckEditProgramPage, isVisitProgramPage, isStaticPrograms, dispatchEvent }}>
      <div className="main-component-wrapper">
      {isLoading ? <LoadingMain/>:<>
          <SocialMediaHeader/>
          <HeaderNavBar/>
          <Routes>
            <Route path="/" element={<Index/>}/>
            <Route path="/about-us" element={<AboutUs/>}/>
            <Route path="/programs/:code?" element={<Programs/>}/>
            <Route path="/gallery" element={<Gallery/>}/>
            <Route path="/enquiry/:id?" element={<ContactUs/>}/>
            <Route path="/login" element={<Login/>}/>
            <Route path="*" element={<NotFoundError/>}/>
          </Routes>
          <Footer height={elementHeight}/>
          </>}
      </div>
    </AppContext.Provider>
  );
}

export default App;
