/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useContext, useState } from 'react'
import { postContact } from '../APIS/ContactUsApi'
import { useLocation, useNavigate} from 'react-router-dom'
import BackToTop from '../Components/BackToTop'
import Heading from '../Components/Heading'
import { AppContext } from '../Context/AppContext'
import PhoneInputWithCountry, { isPossiblePhoneNumber, isValidPhoneNumber, parsePhoneNumber } from "react-phone-number-input"

const responseMessages = require('../Util/responseMessages')

const  ContactUs = ()=> {
    
    const { permanentAllPrograms =[], selectedPrograms=[], filteringPermanentAllPrograms=[], loginUser, isStaticPrograms, dispatchEvent } = useContext(AppContext)

    const [submitValues, setSubmitValues] = useState({fullName:"", designation:"", phone:"", email:"", companyName:" ", message:" "})
    const [isProgramCode, setIsProgramCode] = useState({status:false, code:''})
    const [apiSuccess, setApiSuccess] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [message, setMessage] = useState('')

    const navigate = useNavigate();
    const page = useLocation()

    const resetApiStatus = () => {
        setApiSuccess(false)
        setApiError(false)
        setMessage('')
    }


    const submitHandler = async (e) =>{
        resetApiStatus()
        e.preventDefault()
        let isEmail = !!loginUser
        const { phone, email, ...otherData} = submitValues
        const valid = dataValidate({phone, email})
        if(valid){
            try{
                const phoneNumber = parsePhoneNumber(phone)
                const fullPhoneNumber = phoneNumber.countryCallingCode+phoneNumber.nationalNumber

                const response =  await postContact({...otherData, phone:fullPhoneNumber, email, selectedPrograms, isEmail})
                const {data} = response
                setApiSuccess(true)
                setMessage(data.message || responseMessages.contactUs.add)
                dispatchEvent("CLEAN_SELECTED_PROGRAMS", selectedPrograms)
                setTimeout(()=>{
                    setSubmitValues({fullName:"",designation:"", phone:"", email:" ", companyName:" ", message:" "})
                    document.querySelector("#contact-us-form").reset()
                    navigate(`/enquiry`)
                    resetApiStatus()
                },1000)
            }
            catch(error){
                let takeMessage = error?.message
                const response = error.response
                console.log(error)
                if(response){
                    const {status, data} = response
                    const message = data.message
                    if(status === 400){
                        const {message} = data[0]
                        takeMessage = message || responseMessages.error[400]
                    }
                    else if(status === 500){
                        takeMessage = message || responseMessages.error[500]
                    }
                }
                setApiError(true)
                setMessage(takeMessage)
            }
        }
    }
    const dataValidate = (value) => {
        let notError = true
        if(value.hasOwnProperty('phone')){
            if(value.phone !== undefined){
                if(isPossiblePhoneNumber(value.phone) === true && isValidPhoneNumber(value.phone) === true){
                    notError = true
                }
                else{
                    setApiError(true)
                    setMessage("Invalid Phone Number")
                    return false
                }
            }
        }
        if(value.hasOwnProperty('email')){
            let isValidEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g
            if(submitValues.email.match(isValidEmail)){
                notError = true
            }
            else{
                setApiError(true)
                setMessage("Invalid E-mail Address")
                return false
            }
        }
        return notError
    }
    function inputHandler(e){
        e.preventDefault();
        const {name, value} = e.target;
        if(name=== "full_name"){setSubmitValues({...submitValues, fullName:value})};
        if(name=== "designation"){setSubmitValues({...submitValues, designation:value})};
        if(name=== "email"){setSubmitValues({...submitValues, email:value})};
        if(name=== "company_name"){setSubmitValues({...submitValues, companyName:value})};
        if(name=== "message"){setSubmitValues({...submitValues, message:value})};
        if(name=== "program"){
            const findItem = selectedPrograms.find(item => item === value);
            if(findItem){
                dispatchEvent("REMOVE_SELECTED_PROGRAMS", findItem)
                page.pathname === '/' ?
                navigate(`/`) :
                navigate(`/enquiry`)
            }
            else{
                dispatchEvent("ADD_SELECTED_PROGRAMS", value)
                page.pathname === '/' ?
                navigate(`/`) :
                navigate(`/enquiry`)
            }
            if(isProgramCode.code === value && isProgramCode.code){
                setIsProgramCode({status:false, code:''})
            }
        };
    }
    const inputHandlerPhoneNumber = (value) => {
        setSubmitValues({...submitValues, phone:value})
    }
  return (
    <>
        <div className="main-component-body d-flex flex-column align-items-center">
            <Heading>ENQUIRY</Heading>
            <section className="contact-us-container mt-5">
                <div className="mail-box-div" id="mail-box-div-white">
                    <img src="/assets/icons/contact/mail-red-large.svg" alt="" srcSet="" />
                </div>
                <form className="form-wrapper" id="contact-us-form" onSubmit={(e)=>submitHandler(e)}>
                    <div className="main-component-header heading-align-center">
                        <h2>Reach Out to Us</h2>
                    </div>
                    <div className="mx-md-5 mx-1">
                    {apiSuccess && <p className={`alert alert-success text-center`} >{message}</p>}
                    {apiError && <p className={`alert alert-danger text-center`} >{message}</p>}
                    {!message && <p className="alert my-3"></p>}
                    </div>
                    <div className="form-body-wrapper">
                        <div className="form-fill-div">
                            <div className="form-item">
                            <label htmlFor="" id="form-item-label">Full Name <span className="is-required-red">*</span></label>
                            <input 
                                type="text" 
                                name="full_name" 
                                id="form-item-input-white" 
                                placeholder="Ahmad Ahmad"
                                value={submitValues.fullName}
                                required
                                onChange={(e)=>inputHandler(e)}
                            />
                            </div>
                            <div className="form-item">
                                <label htmlFor="" id="form-item-label">Designation <span className="is-required-red">*</span></label>
                                <input 
                                    type="text" 
                                    name="designation" 
                                    id="form-item-input-white" 
                                    placeholder="Senior Manager"
                                    value={submitValues.designation}
                                    required
                                    onChange={(e)=>inputHandler(e)}
                                />
                            </div>
                            <div className="form-item">
                                <label htmlFor="" id="form-item-label">Phone Number
                                <span className="is-required-red">*</span></label>
                                    <PhoneInputWithCountry
                                        name="phone_number"
                                        international={true}
                                        countryOptionsOrder={["MY", "SG", "TH", "VN", "ID" ]}
                                        id="form-item-input-white"
                                        placeholder="+60 18XXXXXXXXX"
                                        countryCallingCodeEditable={true}
                                        control={submitValues.phone}
                                        rules={{ required: true, validate: isPossiblePhoneNumber}}
                                        value={submitValues.phone}
                                        required
                                        onChange={(e)=>inputHandlerPhoneNumber(e)}
                                    />
                            </div>
                            <div className="form-item">
                            <label htmlFor="" id="form-item-label">E-mail <span className="is-required-red">*</span></label>
                            <input 
                                type="email" 
                                name="email" 
                                id="form-item-input-white" 
                                placeholder="ahmad@gmail.com"
                                value={submitValues.email}
                                required 
                                onChange={(e)=>inputHandler(e)}
                            />
                            </div>
                            <div className="form-item">
                            <label htmlFor="" id="form-item-label">Company Name</label>
                            <input 
                                type="text" 
                                name="company_name" 
                                id="form-item-input-white"
                                placeholder="MSP Systems (M) Sdn Bhd"
                                value={submitValues.companyName}
                                onChange={(e)=>inputHandler(e)}
                            />
                            </div>
                            <div className="form-item">
                            <label htmlFor="" id="form-item-label">Message</label>
                            <textarea 
                                name="message" 
                                rows="8" 
                                cols="20" 
                                id="form-item-input-white"
                                placeholder="Your message"
                                
                                value={submitValues.message}
                                onChange={(e)=>inputHandler(e)}
                            ></textarea>
                            </div>
                        </div>
                        <div className="form-checklist-div">
                            <p className="checklist-title">Select Your Programs</p>
                            <div className="scroll-bar">
                                <div id="checklist-item">
                                    {selectedPrograms?.length !== 0 ? permanentAllPrograms?.filter((permanentData, permanentIndex)=>{
                                        return selectedPrograms?.includes(permanentData.code) ? true : false
                                    })?.map((data, index)=>(
                                        <div key={index} className="program-items">
                                            <input type="checkbox" name="program" value={data.code} id={data.code} defaultChecked={true} onClick={(e)=>inputHandler(e)} />
                                            <label htmlFor={data.code} value={data.code}>{data.topic}</label>
                                        </div>
                                    )):
                                        <p className='text-center fw-bold'style={{color:'var(--color-text-on-gray-light)'}}>ADD PROGRAMS TO ENQUIRY LIST</p>
                                    }
                                    <hr/>
                                    {!isStaticPrograms ? filteringPermanentAllPrograms.filter((permanentData, permanentIndex)=>{
                                        return selectedPrograms?.includes(permanentData.code) ? false : true
                                    })?.map((data, index)=>(
                                        <div key={index} className="program-items">
                                            <input type="checkbox" name="program" value={data.code} id={data.code} defaultChecked={isProgramCode.code === data.code ?true:null} onClick={(e)=>inputHandler(e)} />
                                            <label htmlFor={data.code} value={data.code}>{data.topic}</label>
                                        </div>
                                    )):
                                    <div className="d-flex justify-content-center align-items-center" style={{height: '200px',color:'var(--color-text-on-gray-light)'}}>
                                        <h5 className="text-center">We Come With New Programs Soon</h5>
                                    </div>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-component-read-more-wrapper form-send-now-btn-wrapper">
                        <button type="submit" className="form-send-now-btn">
                            SEND NOW
                        </button>
                    </div>
                </form>
            </section>
            {/* About Us Location */}
            <div className="main-component-header d-flex justify-content-center">
                <h2>Find Us</h2>
            </div>
            <div className="main-component-body">
                <section className="location location-details-div">
                {/* Google Map */}
                <div className="google-map">
                    <iframe className="map-class"
                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15935.380714006451!2d101.6863978!3d3.1355713!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc49c104284fb5%3A0x59d02f2c2f681530!2sMsp%20Hitect%20(M)%20Sdn%20Bhd!5e0!3m2!1sen!2slk!4v1713265365933!5m2!1sen!2slk"
                    style={{border:"0"}} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                </div>
                {/* Location Details */}
                <div className="address-box" id="address-box-margin">
                    <div className="address-list">
                    <img src="/assets/icons/contact/address-black.svg" alt="" srcSet="" className="address-list-img" />
                    <p className="address-list-p">
                        Unit 13A-10, Q Sentral, No.2A, Jalan Stesen Sentral 2,<br />
                        KL Sentral 50470, Kuala Lumpur, Malaysia
                    </p>
                    </div>
                    <div className="address-list">
                    <img src="/assets/icons/contact/mail-black.svg" alt="" srcSet="" className="address-list-img" />
                    <a href="http://info@mspos.com" className="mail">info@chamberskills.com</a>
                    </div>
                    <div className="address-list">
                    <img src="/assets/icons/contact/web-black.svg" alt="" srcSet="" className="address-list-img" />
                    <a href="http://www.chamberskills.com.my" className="mail web">www.chamberskills.com</a>
                    </div>
                    <div className="address-list">
                    <img src="/assets/icons/contact/mobile-black.svg" alt="" srcSet="" className="address-list-img" />
                    <p className="address-list-p">+603-22763253</p>
                    </div>
                </div>
                </section>
            </div>
        </div>
      <BackToTop/>
    </>
  )
}

export default ContactUs