/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { AppContext } from '../Context/AppContext'
import ScaleImage from './ScaleImage'
import ProgramsPopup from './ProgramsPopup'

function Album({album,close}) {

  const [popupImage, setPopupImage] = useState('')
  const [popupImageVisible, setPopupImageVisible] = useState(false)
  const [selectedProgramToView, setSelectedProgramToView] = useState({})
  const [isShowProgramDetailsPopUp, setShowProgramDetailsPopUp] = useState(false)

  const { permanentAllPrograms=[], dispatchEvent,} = useContext(AppContext)

    const scaleClickImage= (image)=> {
      setPopupImage(image)
      setPopupImageVisible(true)
  }

    useEffect(()=>{
        dispatchEvent('CHECK_PAGE_HEIGHT')
    },[close])

    const viewMoreProgram = (program)=>{
      setSelectedProgramToView(program)
      setShowProgramDetailsPopUp(!isShowProgramDetailsPopUp)
    }

    const closeProgramDetailsPopUp = () => {
      setShowProgramDetailsPopUp(!isShowProgramDetailsPopUp)
    }
    return (
    <>
        
      <div style={{position:"relative", width:"100%", height:"50px"}} >
        <button className="edit-buttons preview-buttons position-relative" onClick={()=>close()}>Go Back</button>
      </div>
        <div className="w-100">
            <h2 className="text-left">{album.albumName}</h2>
            <h5>{permanentAllPrograms?.filter((data,index)=>{return album.programId === data._id}).map((data, index)=>(<span key={index}>{data?.topic}<span className="ms-4 fw-bold text-primary "style={{fontSize:"14px",cursor:'pointer'}} onClick={()=>viewMoreProgram(data)}>{`VIEW PROGRAM >>>`}</span></span>))} </h5>
            <div className="d-flex flex-column flex-lg-row">
              <p className='m-0 fw-bold me-2'>{album?.date?.split('T')[0]}</p>
              <p className='m-0 fw-bold ms-lg-2'>{album.location}</p>
            </div>
        </div>
        <div className="d-flex flex-wrap justify-content-evenly w-100">
            {album.images.map((image, imageIndex)=>(
                <div className="gallery-box m-2" key={imageIndex}>
                    <img src={`/${image?.storageName}`} alt='' className="gallery-pictures" onClick={()=>scaleClickImage(image?.storageName)}/>
                </div>
            ))}
        </div> 
        <ScaleImage visible={popupImageVisible} close={setPopupImageVisible} image={popupImage}/>
        <ProgramsPopup visible={isShowProgramDetailsPopUp} close={closeProgramDetailsPopUp} data={selectedProgramToView} />
    </>
  )
}

export default Album