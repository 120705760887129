/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext,useEffect,useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../Context/AppContext'
import { fetchUsers } from '../APIS/UsersApi'

const responseMessages = require('../Util/responseMessages')

const Login = () => {
    
    const { loginUser, dispatchEvent } = useContext(AppContext)

    const [userData, setUserData] = useState({email:'', password:'',})    
    const [apiSuccess, setApiSuccess] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [message, setMessage] = useState('')

    const navigate = useNavigate();

    const resetApiStatus = () => {
        setApiSuccess(false)
        setApiError(false)
        setMessage('')
    }

    const handleAddUser = async (e) => {    
        resetApiStatus()
        e.preventDefault();
        try{
            const response = await fetchUsers(userData)                         
            const token = response.data
            const status = response.statusText
            dispatchEvent('SET_LOGIN_USER_TO_COOKIES', token )
            setApiSuccess(true)
            setMessage(responseMessages.user.found || status)
            setTimeout(()=>{
                navigate('/programs')
            },500)    
        }catch(err){
            let message = err?.message;
            const response = err.response;    
            if(response && (response.status === 400) ){
                const data = response.data                
                message = responseMessages.user.notFound || data.message;          
                if(Array.isArray(data) && data.length){                
                    message = data.reduce((pre, cur) => `${pre} | ${cur.message}`, '')                                                          
                }                
            }else if(response && (response.status === 404) ){                
                message = responseMessages.user.notFound;          
            }else if(response && response.status === 500){                                   
                message = responseMessages.error[500] || response.data;          
            }
            setApiError(true)
            setMessage(message)
        }    
    }

    const handleLogin = (e) => {
        e.preventDefault();
        const {name, value} = e.target
        if (name === 'email'){setUserData({...userData,email:value})}
        if (name === 'password'){setUserData({...userData,password:value})}
    }

    useEffect(() => {
        if(loginUser){
            navigate('/programs')
        }
    },[])

    return (
    <div className="d-flex flex-column align-item-center mt-5" style={{marginBottom:'60px'}}>
        {/* Alert */}
        {apiSuccess && <p className={`alert alert-success text-center`} >{message}</p>}
        {apiError && <p className={`alert alert-danger text-center`} >{message}</p>}
        {!message && <p className="alert my-3"></p>}

        <form onSubmit={(e)=>handleAddUser(e)} className="d-flex flex-column form-box">
            <h3 className='text-center'><b>LOGIN</b></h3>
            <div className="d-flex flex-column mt-4">
                <label><b>Email</b></label>
                <input 
                    type="email" 
                    name="email" 
                    placeholder="jone@gmail.com" 
                    required 
                    value={userData.email}
                    onChange={(e)=>handleLogin(e)}
                    className="form-control"
                />               
            </div>
            <div className="d-flex flex-column mt-4">
                <label><b>Password</b></label>
                <input 
                    type="password" 
                    name="password" 
                    placeholder="**********"
                    required 
                    value={userData.password}
                    onChange={(e)=>handleLogin(e)}
                    className="form-control"
                />               
            </div>
            <div className="d-flex flex-column mt-4 align-items-center">
                <button type="submit" className="mt-5 gallery-view-more-btn">Log in</button>
            </div>
        </form>
    </div>
  )
}

export default Login