import React, { useState } from 'react'

function BackToTop() {
    const [visible, setVisible] = useState(false)

    const toggleVisible = () => { 
        const scrolled = document.documentElement.scrollTop; 
        if (scrolled > 300){ 
          setVisible(true) 
        }  
        else if (scrolled <= 300){ 
          setVisible(false) 
        } 
    }; 

    function backToTop() {
        window.scrollTo({ 
        top: 0,  
        behavior: 'smooth'
        });
    }
    window.addEventListener('scroll', toggleVisible);
  return (
    <div id="back-to-top" style={{display: visible ? 'block':'none'}} onClick={()=>backToTop()}></div>
  )
}

export default BackToTop