import React, { useContext, useState } from 'react'
import { SubHeadingForme } from './Heading'
import { AppContext } from '../Context/AppContext'
import { deleteAlbum } from '../APIS/GalleryApi'

const responseMessages = require('../Util/responseMessages')

const AlbumDelete = ({ visible, close, deleteAlbumData, }) => {
    const [apiSuccess, setApiSuccess] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [message, setMessage] = useState('')

    const { dispatchEvent } = useContext(AppContext)

    const resetApiStatus = () => {
        setApiSuccess(false)
        setApiError(false)
        setMessage('')
    }

    const handleDeleteAlbum = async(id)=>{
        resetApiStatus()
        try{
            const response = await deleteAlbum(id)
            const {message} = response.data
            setMessage( message || responseMessages.common.deleted(responseMessages.type.album))
            setApiSuccess(true)
            setTimeout(()=>{
                dispatchEvent('GET_ALBUMS')
                closeDeleteRequest()
            },1000)
        }catch(error){
            let takeMessage = error?.message
            const response = error.response
            if(response){
                const {status, data} = response
                const message = data.message
                if(response && status === 400){
                    const message = data[0].message
                    takeMessage = message || responseMessages.error[400] 
                }
                else if(response && status === 404){
                    takeMessage = message || responseMessages.error[404](responseMessages.type.album) 
                }
                else if(response && status === 500){
                    takeMessage = message || responseMessages.error[500] 
                }
            }
            setApiError(true)
            setMessage(takeMessage)
        }
    }
    const closeDeleteRequest = () => {
        resetApiStatus()
        close(false)
    }
    return (
        <div style={{display: visible ? 'block': 'none'}}>
            <div className="program-modal-blur-background" style={{display: 'block',zIndex:'1'}}></div>
            <div className="program-modal d-flex flex-column align-items-center justify-content-center">
                <div className="program-popup-box">
                    <SubHeadingForme>DELETE ALBUM</SubHeadingForme>
                    {apiSuccess && <p className={`alert alert-success text-center`} >{message}</p>}
                    {apiError && <p className={`alert alert-danger text-center`} >{message}</p>}
                    {!message && <p className="alert my-3"></p>}
                    <p  style={{textAlign:"center",color:"black"}}  className="program-popup-massage">Are you sure you want to delete ?<br/><span style={{fontWeight:"bold"}}>{deleteAlbumData?.albumName} album</span></p>
                    <div className="d-flex flex-row justify-content-around" style={{width:"100%"}}>
                        <button  className="edit-buttons close-buttons"onClick={()=>closeDeleteRequest()}>No</button>
                        <button  className="edit-buttons delete-buttons" onClick={(e)=>handleDeleteAlbum(deleteAlbumData._id)}>YES</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AlbumDelete