/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import { SubHeadingForme } from './Heading'
import { AppContext } from '../Context/AppContext'
import { uploadProgramsFiles } from '../APIS/FileApi'
import { updateAlbum } from '../APIS/GalleryApi'
import ImageDelete from './ImageDelete'

const responseMessages = require('../Util/responseMessages')

const AlbumUpdateForm = ({close,data}) => {
    const { permanentAllPrograms=[], dispatchEvent } = useContext(AppContext)
    const [albumDetails, setAlbumDetails] = useState({})
    const [images, setImages] = useState([])
    const [apiSuccess, setApiSuccess] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [message, setMessage] = useState('')
    const [deleteImageDetails, setDeleteImageDetails] = useState({})
    const [deleteImagesTrigger, setDeleteImagesTrigger] = useState(false)


    const albumObject = {albumName:'', date:undefined, location:'', programId:'', thumbnailImage:''}

    useEffect(()=>{
      const {images, ...allData} = data
        setAlbumDetails(allData)
        setImages(images)
        dispatchEvent("CHECK_PAGE_HEIGHT")
    },[ close, data ])

    useEffect(()=>{
      const setThumbnail = typeof albumDetails.thumbnailImage === 'object' ? albumDetails.thumbnailImage?._id : albumDetails.thumbnailImage
      const isHasThumbnail = images.filter((image,index)=>{return image._id === setThumbnail})
      if(setThumbnail && (isHasThumbnail.length === 0)){
        setAlbumDetails({...albumDetails, thumbnailImage:null})
      }
    },[ images ])

    const resetApiStatus = () => {
      setApiSuccess(false)
      setApiError(false)
      setMessage('')
    }
    const resetAlbumFields = () => {
      setAlbumDetails({})
      setImages([])
    }
    const uploadFile = async (file)=>{
        try{
            let attachmentsList = images
            const response = await uploadProgramsFiles(file)
            const uploadFileData = response.data;
            attachmentsList.push(uploadFileData)
            setImages(attachmentsList)
            setApiSuccess(true)
            setMessage(responseMessages.common.add(message || responseMessages.type.image))
            setTimeout(()=>{
                resetApiStatus()
            },1000)
        }
        catch(error){
            let takeMessage = error?.message
            const response = error?.response
            console.log(error)
            if(response){
              const {status, data} = response
                const message = data?.message
                if(response && status === 400){
                    const message = data[0]?.message
                    takeMessage = message || responseMessages.error[400]
                }
                else if(response && status === 500){
                    takeMessage = message || responseMessages.error[500]
                }
                setApiError(true)
                setMessage(takeMessage)
            }
        }   
    }
    const closeForme = ()=> {
        resetApiStatus()
        resetAlbumFields()
        close()
    }
  
    const handleSubmit = async(e)=> {
        resetApiStatus()
        e.preventDefault()
        try{
            const {_id, albumName, date=undefined, location='', programId='', thumbnailImage=''} = albumDetails
            const setThumbnail = typeof thumbnailImage === 'object' ? thumbnailImage?._id : thumbnailImage
            const programDetailsCovertData =  images.map((i) => i._id)
            const response = await updateAlbum(_id,{albumName, images:programDetailsCovertData, date, location, programId, thumbnailImage:setThumbnail})
            const {message} = response.data
            setApiSuccess(true)
            setMessage(message || responseMessages.common.add(responseMessages.type.album))
            setTimeout(()=>{
              dispatchEvent('GET_ALBUMS')
              closeForme()  
            },1000)
        }
        catch (error) {
            let takeMessage = error?.message
            const response = error?.response
            console.log(error)
            if(response){
                const {status, data} = response
                const message = data?.message
                if(response && status === 400){
                    const message = data[0]?.message
                    takeMessage = message || responseMessages.error[400]
                }
                else if(response && status === 404){
                    takeMessage = message || responseMessages.error[404](responseMessages.type.album)
                }
                else if(response && status === 409){
                    takeMessage = message || responseMessages.error.topic409(responseMessages.type.album)
                }
                else if(response && status === 500){
                    takeMessage = message || responseMessages.error[500]
                }
            }
            setApiError(true)
            setMessage(takeMessage)
        }
    } 
    const handleValue = (e)=> {
      e.preventDefault()
      const {name, value} = e.target
      const isKeyExist = albumObject.hasOwnProperty(name)
      if(isKeyExist){
          const obj = {}
          obj[name] = value
          setAlbumDetails({...albumDetails, ...obj})
      }
    }
    const handleAddImages = async(e)=> {
        e.preventDefault()
        let value=e.target.files[0]
        if(value){
            const formData = new FormData();
            formData.append("file",value);
            await uploadFile(formData)
        }
    }
    const handleRemoveImage = (deleteImage, index) =>{
      setDeleteImageDetails({...deleteImage,index})
      triggerImageDeleteConform(true)
    }
    const removeImage = (state ,index)=>{
      if(state){
        let defaultImageList = images
        defaultImageList.splice(index,1)
        setImages([...defaultImageList])
      }
    }
    const triggerImageDeleteConform =(status)=> {
      setDeleteImagesTrigger(status)
    }
    return (
      <>
        <SubHeadingForme>UPDATE ALBUM</SubHeadingForme>
        <div style={{position:"relative", width:"100%", height:"50px"}}>
          <button className="edit-buttons preview-buttons position-relative" onClick={()=>closeForme()}>Go Back</button>
        </div>
        <div className="d-flex align-items-center justify-content-center" style={{width: '100%'}}>
            {apiSuccess && <p className={`alert alert-success text-center`} >{message}</p>}
            {apiError && <p className={`alert alert-danger text-center`} >{message}</p>}
            {!message && <p className="alert my-3 text-center"></p>}
        </div>
        <form className='d-flex flex-wrap align-items-center justify-content-center' onSubmit={(e)=>handleSubmit(e)}>
          <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
            <input 
              type="text" 
              className="form-control my-2 me-sm-2"
              id="exampleFormControlInput1"
              name="albumName"
              value={albumDetails?.albumName||""}
              onChange={(e)=>{handleValue(e)}}
              placeholder='Album Name'
            />
            <div className="d-flex flex-row align-items-center w-100 ms-sm-2" >
              <label className='text-nowrap'>Related Date</label>
              <input 
                type="date" 
                className="form-control my-2 ms-2"
                id="exampleFormControlInput1"
                value={albumDetails?.date?.split('T')[0] || ""}
                onChange={(e)=>{handleValue(e)}}
                name='date'
              />
            </div>
          </div>
          <div className='d-flex flex-column flex-sm-row' style={{width: '100%'}}>
            <input 
              type="text" 
              className="form-control my-2 me-sm-2"
              id="exampleFormControlInput1"
              name="location"
              value={albumDetails?.location||""}
              onChange={(e)=>{handleValue(e)}}
              placeholder='Location'
            />
            <select 
              className="form-select my-2 ms-sm-2 form-select-sm w-100"
              value={albumDetails?.programId||""}
              name="programId"
              onChange={(e)=>{handleValue(e)}}
            >
              <option id={''} value='' className='text-wrap w-50'>--Related Program--</option>
              {permanentAllPrograms?.map((data,index)=>(
                <option id={index} value={data._id} className='text-wrap w-50' key={index}>{data.topic}</option>
              ))}
            </select>
          </div>
          <input 
            type='file' 
            className="form-control my-2"
            id="formFile"
            name="uploadImage"
            accept="image/png, image/jpeg, image/apng, image/bmp, image/gif, image/jpg, image/pjpeg, image/png, image/svg+xml, image/tiff, image/webp, image/x-icon"
            onChange={(e)=>{handleAddImages(e)}}
          />
          <button 
            className="edit-form-buttons" 
            disabled={Array.isArray(images) && images.length >= 2 ? false : true}
            style={{opacity:Array.isArray(images) && images.length >= 2 ?"1":'0.5', transform:Array.isArray(images) && images.length >= 2? '':'scale(1)'}}
          >
              Update Album
          </button>
          <hr style={{width:'100%',borderSize:'2px'}}/>
          {Array.isArray(images) && images.length !== 0 ?
            <div className="d-flex flex-wrap justify-content-around" style={{width:"100%"}}>
              {images.map((data,index)=>(
                <div className="gallery-box position-relative m-2" key={index}>
                  <div className="position-absolute left-0 ms-2 z-1 d-flex flex-row align-items-center" style={{top:'5px'}}>
                    <input 
                      type="radio" 
                      id={data?._id} 
                      value={data?._id} 
                      name="thumbnailImage" 
                      className='form-check-input' 
                      onChange={(e)=>{handleValue(e)}} 
                      checked={data?._id === albumDetails?.thumbnailImage?._id || data?._id === albumDetails?.thumbnailImage ? true : false}
                    />
                    <label htmlFor={data?._id} className="m-0 ms-2 text-white ">Click to thumbnail</label>
                  </div>
                  <div className="position-absolute d-flex flex-row align-items-center justify-content-end px-3 py-1" style={{left:"0",right:"0",top:"0",height:'30px',backgroundColor:"rgba(0, 0, 0, 0.5)",width:'100%'}}>
                    <img src="/assets/icons/delete-icon.svg" alt={""} style={{height:'90%'}} className='align-self-end' onClick={(e)=>handleRemoveImage(data, index, e)} />
                  </div>
                  <img src={`/${data?.storageName}`} alt='' className="gallery-pictures" style={{cursor:'context-menu'}} />
                </div>
              ))}
            </div>
          :<p className='text-center'style={{width:'100%'}}>ADD TWO OR MORE IMAGES FOR ALBUM</p>}
        </form>
        <ImageDelete visible={deleteImagesTrigger} close={triggerImageDeleteConform} deleteImage={deleteImageDetails} callDelete={removeImage}/>
      </>
    )
}

export default AlbumUpdateForm