import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
const aboutUsContent = require('../Static-Data/about-us-content.json');


function AboutUsIndex() {

  const [whoWeAreDescribing, setWhoWeAreDescribing]= useState([])
  const [imageOne , setImageOne ]= useState("")
  const [imageTwo , setImageTwo ]= useState("")
  useEffect(()=>{
    setWhoWeAreDescribing(aboutUsContent?.aboutUsText[0]?.description.slice(0, 2))
    setImageOne(aboutUsContent?.aboutUsImages[2]?.image)
    setImageTwo(aboutUsContent?.aboutUsImages[3]?.image)
  },[])

  return (
    <section className="about-us-section py-4">
          <div className="about-us-image-box-index">
              <div className="about-us-image-empty"></div>
              <img src={`./assets/images/wallpapers/${imageOne}`} alt='' className="about-us-image about-us-image-two"/>
              <img src={`./assets/images/wallpapers/${imageTwo}`} alt='' className="about-us-image about-us-image-one"/>
          </div>
          <div className="about-us-text-box">
              <p className="about-us-text-header">ABOUT US</p>
              <p className="about-us-text-sub-header">A Few Words About Us</p>
              {whoWeAreDescribing?.map((data, index)=>(
                <p className="about-us-text" key={index}>
                  {data}
                </p>
              ))}
              <Link to="/about-us" className="about-us-btn">READ MORE</Link>
          </div>
        </section>
  )
}

export default AboutUsIndex