import React from 'react'

function LoadingMain() {
  return (
    <div className="loading-tex-box">
        <p className="main-loading-text">CHAMBER SKILLS</p>
    </div>
  )
}

export default LoadingMain