/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext } from 'react'
import { AppContext } from '../Context/AppContext'
import ProgramsPage from '../Components/ProgramsPage'
import ProgramsManage from './ProgramsManage'

function Programs() {
    const { loginUser } = useContext(AppContext)
  return(
    <section className="program-container d-flex flex-column align-items-center mb-5">
         { loginUser ?  <ProgramsManage/>  : <ProgramsPage/>}
    </section>
)
}
export default Programs
