/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react'
import Heading, { SubHeading } from './Heading'
import { Link } from 'react-router-dom'
import ProgramsPopup from './ProgramsPopup'
import { AppContext } from '../Context/AppContext'

const ProgramsIndex = () => {

    const { programs =[], selectedPrograms=[], dispatchEvent, isStaticPrograms } = useContext(AppContext)  
    
    const [isShowProgramDetailsPopUp, setIsShowProgramDetailsPopUp] = useState(false)
    const [selectedProgramToView, setSelectedProgramToView] = useState({})
    
    useEffect(() =>{
        dispatchEvent("GET_ALL_PROGRAMS_BY_CONDITION",{page:1, limit:3, sortOrder:`ASC`,sortBy:`_id`})
    },[])

    const showProgramDetailsPopUp = () => {
        setIsShowProgramDetailsPopUp(true)
    }
    const closeProgramDetailsPopUp = () => {
        setIsShowProgramDetailsPopUp(false)
    }
    const viewProgramDetails = (program) => {
        showProgramDetailsPopUp();
        setSelectedProgramToView(program)
    }
    
    const truncate = (description) => {
        return  description.length > 170 ? description.substring(0, 150) + "..." : description
    }
    const checkSelected = (code)=>{
        if(code !== undefined){
            const found = selectedPrograms.findLast(indexIn=>(code === indexIn))
            return found
        }
    }
    const identifyDurationType = (time , type)=>{
        const timeDurationTypes = {hours:'hour', days:'day', weeks:'week', months:'month', years:'year'}
        const isTypeExist = timeDurationTypes.hasOwnProperty(type)
        if(time === 1 && isTypeExist){
            return timeDurationTypes[type]
        }
        else{
            return type
        }
    }
  return (
    <div>
        <section className="index-program-container d-flex flex-column align-items-center">
        <Heading>PROGRAMS</Heading> 
        <SubHeading>Few Programs from Many</SubHeading>
        <div className="index-programs-container">
            <div style={{width:"1200px"}} className="d-flex flex-row ">
            {programs?.slice(0,3)?.map((program, index) =>(              
                <div key={index} className="index-programs-item position-relative" style={isStaticPrograms?{border:'4px double',borderTopLeftRadius:'0'}:{}} id={program.code}>
                    {checkSelected(program.code) ?<p className="program-enquiry-mark" ><span className="program-enquiry-mark-content"><span className="program-enquiry-mark-content-item">ENQUIRY</span></span></p>:''}
                    <h4 className="program-title">{program.topic}</h4>
                    <div className="d-flex flex-row my-3">
                            { program.isDuration ?
                                <div className='d-flex flex-row align-items-center'>
                                        <img src="/assets/icons/duration-icon.svg" alt="" className="program-card-icon"/>
                                        <p className='m-0 ms-2 me-2 program-details'>{`${program.durationTime} ${identifyDurationType(program.durationTime, program.durationType)}`}</p>
                                </div>
                            :''}
                            { program.isCertificate ?
                                <div className='d-flex flex-row align-items-center'>
                                    <p className='m-0 me-2 program-details fw-bold'>CERTIFICATE</p>
                                    <img src="/assets/icons/citification-tick.svg" alt="" className="program-card-icon"/>
                                </div>
                            : ''}
                        </div>
                    <p className="index-program-details">{truncate(program.desc)}</p>
                    <button className="index-program-read-more" onClick={()=>viewProgramDetails(program)}>Read More</button>
                </div>
            ))}
            </div>
        </div>
        <Link to='/programs'  id="index-view-more-programs-btn" className="text-center text-decoration-none">SEE ALL</Link>
        <ProgramsPopup visible={isShowProgramDetailsPopUp} close={closeProgramDetailsPopUp} data={selectedProgramToView} />
    </section>
    </div>
  )
}

export default ProgramsIndex