/* eslint-disable no-loop-func */
import React, { useContext, useEffect, useState } from 'react'
import { SubHeadingForme } from './Heading'
import { updatePrograms } from '../APIS/ProgramsApi'
import { uploadProgramsFiles } from '../APIS/FileApi'
import { AppContext } from '../Context/AppContext'

const responseMessages = require('../Util/responseMessages')

const ProgramUpdateForm = ({visible, close, defaultValues,page}) => {

    const defaultProgramsObj = {topic: '', desc: '', isDuration:false, durationTime:1, durationType:"days", isCertificate:false, certification:'', publishStartDate:undefined, publishEndDate:undefined, minimumCriteria:[], attachments:[], links:[]}

    const [programDetails, setProgramDetails] = useState('')
    const [criteria, setCriteria] =useState()
    const [links, setLinks] = useState()
    const [programUpdate, setProgramUpdate] = useState(true)
    const [apiSuccess, setApiSuccess] = useState(false)
    const [apiError, setApiError] = useState(false)
    const [message, setMessage] = useState('')
    const [isEditCriteria, setIsEditCriteria] = useState({id:'',value:''})

    const { dispatchEvent } = useContext(AppContext)
    useEffect(()=>{
        setProgramDetails(defaultValues)
    },[defaultValues,visible])
    // useEffect(()=>{
    //     let isUpdate = false
    //     if(defaultValues?._id !== undefined){
    //         const defaultKeyCount = Object.keys(defaultValues).length
    //         const updateKeyCount = Object.keys(programDetails)?.length
    //         if(updateKeyCount === defaultKeyCount){
    //             for(let i = 0; i < defaultKeyCount; i++){
    //                 let [defaultKey, defaultValue] = Object.entries(defaultValues)[i]
    //                 for(let n = 0; n < updateKeyCount; n++ ){
    //                     let [updateKey, updateValue] = Object.entries(programDetails)[n]
    //                     if(!isUpdate){
    //                         if(updateKey === defaultKey){
    //                             if((updateValue !== null && updateValue !== undefined) &&  (defaultValue !== null && defaultValue !== undefined)){
    //                                 if(Array.isArray(updateValue) && Array.isArray(updateValue) ){
    //                                     updateValue.map((updateData,updateIndex)=>(
    //                                         defaultValue.map((defaultData,defaultIndex)=>{
    //                                             if(updateIndex === defaultIndex){
    //                                                 if(defaultData === updateData){
    //                                                     isUpdate = false
    //                                                 }
    //                                                 else{
    //                                                     isUpdate = true
    //                                                 }
    //                                             }
    //                                         })
    //                                     ))
    //                                 }
    //                                 else if(updateKey === "publishStartDate" || updateKey === "publishEndDate"){
    //                                     if(updateValue?.split('T')[0] === defaultValue?.split('T')[0]){
    //                                         isUpdate = false
    //                                     }
    //                                     else{
    //                                         isUpdate = true
    //                                     }
    //                                 }else{
    //                                     if((updateValue === defaultValue) || (Number(updateValue) === defaultValue)){
    //                                         isUpdate = false
    //                                     }
    //                                     else{
    //                                         isUpdate = true
    //                                     }
    //                                 }
    //                             }
    //                         }
    //                     }
    //                 }
    //             }
    //         }
    //     }
    //     setProgramUpdate(isUpdate)
    // },[defaultValues, programDetails])
    
    const resetApiStatus = () => {
        setApiSuccess(false)
        setApiError(false)
        setMessage('')
    }

    const resetProgram = () => {
        setProgramDetails(defaultProgramsObj)
    }

    function handelInputValue(e){
        e.preventDefault()
        const {name, value} = e.target
        const isKeyExist = defaultProgramsObj.hasOwnProperty(name)
        if(isKeyExist){
            const obj = {}
            obj[name] = value
            setProgramDetails({...programDetails, ...obj})
        }
    }

    const handleSubmit = async (e)=>{
        resetApiStatus()
        e.preventDefault()

        const createdDate = programDetails.createdAt.split('T')[0]
        const publishDate = programDetails.publishStartDate.split('T')[0]
        if(createdDate <= publishDate){
            try{
                const {_id, topic, desc, isDuration, durationTime, durationType, isCertificate, certification, publishStartDate, publishEndDate, minimumCriteria, attachments = [], links } = programDetails
                const response = await updatePrograms(_id, {topic, desc, isDuration, durationTime, durationType, isCertificate, certification, publishStartDate, publishEndDate, minimumCriteria, attachments : attachments.map((i) => i._id), links})
                const {message} = response.data
                setApiSuccess(true)
                setMessage(responseMessages.common.updated(message || responseMessages.type.programs))
                setTimeout(()=>{
                    dispatchEvent('GET_ALL_PROGRAMS_BY_TOKEN',{page:page.pageCount, limit:7, sortOrder:page.sortOrder,sortBy:page.sortBy})
                    closeForme()
                },1000)
            }
            catch(error) {
                let takeMessage = error?.message
                const response = error?.response
                console.log(error)
                if(response){
                    const {status, data} = response
                    const message = data?.message
                    if(response && status === 400){
                        const message = data[0]?.message
                        takeMessage = message || responseMessages.error[400]
                    }
                    else if(response && status === 404){
                        takeMessage = message || responseMessages.error[404](responseMessages.type.programs)
                    }
                    else if(response && status === 500){
                        takeMessage = message || responseMessages.error[500]
                    }
                }
                setApiError(true)
                setMessage(takeMessage)
            }
        }
        else{
            setApiError(true)
            setMessage(`Publish Start Date must be grater than or equal program created date (${createdDate})`)
        }
    }
    const uploadFile = async (file)=>{
        try{
            let attachmentsList = programDetails.attachments
            const response = await uploadProgramsFiles(file)
            const uploadFileData = response.data;
            attachmentsList.push(uploadFileData)
            setProgramDetails({...programDetails,attachments:attachmentsList})
            setApiSuccess(true)
            setMessage(responseMessages.common.add(message || responseMessages.type.attachment))
            setTimeout(()=>{
                resetApiStatus()
            },1000)
        }
        catch(error){
            let takeMessage = error?.message
            const response = error?.response
            console.log(error)
            if(response){
                const {status, data} = response
                const message = data?.message
                if(response && status === 400){
                    const message = data[0]?.message
                    takeMessage = message || responseMessages.error[400]
                }
                else if(response && status === 500){
                    takeMessage = message || responseMessages.error[500]
                }
                setApiError(true)
                setMessage(takeMessage)
            }
        }   
    }
    const handleInputCriteria = (e) => {
        setCriteria(e.target.value)
    }
    const handleAddCriteriaList = (e)=>{
        e.preventDefault()
        let list = programDetails.minimumCriteria
        if(criteria){
            list.push(criteria)
            setProgramDetails({...programDetails,minimumCriteria:list})
            setCriteria('')
        }
    }
    const handleRemoveCriteriaList = (e, id) => {
        e.preventDefault()
        let list = programDetails.minimumCriteria
        list.splice(id,1)
        setProgramDetails({...programDetails,minimumCriteria:list})
    }
    const handleEditCriteria = (e, id)=>{
        e.preventDefault()
        let list = programDetails.minimumCriteria
        setIsEditCriteria({id:id,value:list[id]})
    }
    const handleInputEditCriteria = (e) =>{
        e.preventDefault()
        setIsEditCriteria({...isEditCriteria,value:e.target.value})
    }
    const handleDoneEditCriteria = (e) =>{
        e.preventDefault()
        let list = programDetails.minimumCriteria
        let id = isEditCriteria.id 
        list[id] = isEditCriteria.value
        setProgramDetails({...programDetails, minimumCriteria:list})
        setIsEditCriteria({id:'',value:''})
    }
    const handleCloseEditCriteria = (e) =>{
        e.preventDefault()
        setIsEditCriteria({id:'',value:''})
    }
    const handleAttachments = async(e) => {
        e.preventDefault()
        let value=e.target.files[0]
        if(value){
            const formData = new FormData();
            formData.append("file",value);
            await uploadFile(formData)
        }
    }
    const handleRemoveAttachments = (e, id) => {
        e.preventDefault()
        let list = programDetails.attachments 
        list.splice(id,1)
        setProgramDetails({...programDetails, attachments:list})
    }
    const handleCheckboxes = (e) => {
        const {name} = e.target
        if(name === 'isDuration'){setProgramDetails({...programDetails, isDuration:!programDetails.isDuration, durationTime:1,durationType:"days"})}
        if(name === 'isCertificate'){setProgramDetails({...programDetails, isCertificate:!programDetails.isCertificate, certification:''})}
    }
    const handleInputLinks = (e) =>{
        setLinks(e.target.value)
    }
    const handleAddLinkList =(e)=>{
        e.preventDefault()
        let list = programDetails.links
        if(links){
            list.push(links)
            setProgramDetails({...programDetails,links:list})
            setLinks('')
        }
    }
    const handleRemoveLinkList = (e, id) => {
        e.preventDefault()
        let list = programDetails.links
        list.splice(id,1)
        setProgramDetails({...programDetails,links:list})
    }
    const handleSetPublishStartDate = (e) => {
        const {value} = e.target
        const getDate = new Date(value).toISOString()
        setProgramDetails({...programDetails, publishStartDate:getDate})     
    }
    const handleSetPublishEndDate = (e) => {
        const {value} = e.target
        const getISODate = new Date(value).toISOString()
        const getISODateHour = new Date(getISODate).setHours(29,29,59)
        const setDate = new Date(getISODateHour).toISOString()
        setProgramDetails({...programDetails, publishEndDate:setDate})
    }
    function closeForme (){
        resetProgram()
        resetApiStatus()
        document.querySelector("#programs-edit-form").reset()
        close(false)
    }
    return (
    <>
         <div className="program-modal-blur-background" style={{display: visible ? 'block': 'none'}}></div>
        <div className="program-modal"  style={{display: visible ? 'block': 'none'}}>
            <div className="program-modal-content" >
                <div className="form-heading-box">
                    <SubHeadingForme>PREVIEW & UPDATE PROGRAM</SubHeadingForme>
                    <span className="program-close-button" onClick={()=>closeForme()}>×</span>
                </div>
                <div className="d-flex align-items-center justify-content-center" style={{width: '100%'}}>
                    {apiSuccess && <p className={`alert alert-success text-center`} >{message}</p>}
                    {apiError && <p className={`alert alert-danger text-center`} >{message}</p>}
                    {!message && <p className="alert my-3 text-center"></p>}
                </div>
                <form className="d-flex flex-column align-items-center" onSubmit={(e)=>handleSubmit(e)} id="programs-edit-form">
                    <div className="d-flex flex-column mb-3 edit-input-box">
                        <label>Code</label>
                        <input 
                            type="text" 
                            name='code'
                            className="px-2" 
                            value={programDetails?.code  || ""}
                            placeholder="LA001"
                            disabled = {true}
                            onChange={(e)=>handelInputValue(e)}
                        />
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box">
                        <label>Topic</label>
                        <input 
                            type="text"
                            name='topic'
                            className="px-2" 
                            value={programDetails?.topic || ""}
                            placeholder="Learn Scrum Master Leadership" 
                            required = {true}
                            onChange={(e)=>handelInputValue(e)}
                        />
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box">
                        <label>Description</label>
                        <textarea 
                            type="text" 
                            name="desc" 
                            className="px-2" 
                            value={programDetails?.desc || ""}
                            placeholder="Description" 
                            rows="7" 
                            required = {true}
                            onChange={(e)=>handelInputValue(e)}
                        />
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box" >
                        <label>Criteria</label>
                        <div className='d-flex flex-row'>
                            <input 
                                type='text' 
                                name='criteria'  
                                style={{width: '100%'}}
                                value={criteria || ""}
                                onChange={(e)=>{handleInputCriteria(e)}}
                            />
                            <button onClick={(e)=>handleAddCriteriaList(e)} className="programs-list-add-btn button-active-action">+</button>
                        </div>
                        <ul className='align-self-start'  style={{width:"100%"}}>
                        {programDetails?.minimumCriteria?.map((data,index)=>(
                            <div key={index} className='d-flex flex-row mt-3'>
                               {isEditCriteria.id !== index ? <div className='d-flex flex-row' style={{width:"100%"}}>
                                    <li className="mb-0" style={{width:"100%"}}>{`${data}`}</li>
                                    <div className='d-flex flex-row'>
                                        <button onClick={(e)=>handleEditCriteria(e, index)} className='programs-list-Edit-btn button-active-action'>Edit</button>
                                        <button onClick={(e)=>handleRemoveCriteriaList(e, index)} className="programs-list-close-btn button-active-action">X</button>    
                                    </div>
                                </div>:
                                <div className='d-flex flex-row'style={{width: '100%'}}>
                                    <input
                                        type='text' 
                                        name='criteriaEdit'  
                                        style={{width: '100%'}}
                                        value={isEditCriteria.value || ""}
                                        onChange={(e)=>{handleInputEditCriteria(e)}}
                                    />
                                    <button onClick={(e)=>{handleDoneEditCriteria(e)}} className="programs-list-Edit-btn button-active-action">Done</button>
                                    <button onClick={(e)=>handleCloseEditCriteria(e)} className="programs-list-close-btn button-active-action">X</button>
                                </div>
                                }
                            </div>
                        ))}
                        </ul>
                    </div>
                    <div className="d-flex flex-row edit-input-box">
                        <label>Is Provide a certificate ? </label>
                        <input 
                            className='ms-2'
                            type='checkbox' 
                            name='isCertificate'
                            defaultChecked={programDetails?.isCertificate}
                            onClick={(e)=>{handleCheckboxes(e)}}
                        />
                    </div>
                    <div className="d-flex flex-row mb-3 edit-input-box" style={{opacity:programDetails?.isCertificate ? 1: 0.5}}>
                        <input 
                            type='text' 
                            name='certification'
                            disabled={!programDetails?.isCertificate}
                            required={programDetails?.isCertificate}
                            value={programDetails?.certification  || ""}
                            placeholder="Enter the certificate name"
                            onChange={(e)=>{handelInputValue(e)}}
                            style={{width: '100%'}}
                        />
                    </div>
                    <div className="d-flex flex-row edit-input-box">
                        <label>Set Duration : If have </label>
                        <input 
                            className='ms-2'
                            type='checkbox' 
                            name='isDuration'
                            defaultChecked={programDetails?.isDuration}
                            onClick={(e)=>{handleCheckboxes(e)}}
                        />
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box">
                        <div className="d-flex flex-row" style={{width: '100px'}}>
                            <div className='d-flex flex-row me-3 edit-input-box mb-3'  style={{opacity:programDetails?.isDuration ? 1: 0.5}}>
                                <input 
                                    type='number' 
                                    name='durationTime' 
                                    style={{width: '40px',textAlign:'center'}}
                                    disabled={!programDetails?.isDuration}
                                    required={programDetails?.isDuration}
                                    value={programDetails?.durationTime || ""}
                                    placeholder="1"
                                    onChange={(e)=>{handelInputValue(e)}}
                                />
                            </div>
                            <div className='d-flex flex-row mb-3'  style={{opacity:programDetails?.isDuration ? 1: 0.5}}>
                                <select name='durationType' disabled={!programDetails?.isDuration} onChange={(e)=>{handelInputValue(e)}} value={programDetails?.durationType || ""}>
                                    <option value="day">Days</option>
                                    <option value="hours">Hours</option>
                                    <option value="months">Months</option>
                                </select>  
                            </div>
                        </div>
                    </div>
                    <div className="d-flex flex-column edit-input-box mb-3">
                        <label>Add Attachments</label>
                        <input 
                            type='file' 
                            name='attachments'
                            onChange={(e)=>{handleAttachments(e)}}
                        />
                        <ul className='align-self-start' style={{width:"100%"}}>
                            {programDetails?.attachments?.map((data,index)=>(
                                <div key={index} className='d-flex flex-row  mt-3'>                                    
                                    <li className="mb-0 text-wrap" style={{width:"100%",fontSize:"12px"}}>{`${data?.originalName}`}</li> <button onClick={(e)=>handleRemoveAttachments(e, index)} className="programs-list-close-btn">X</button>
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box" >
                        <label>Add Video Link</label>
                        <div className='d-flex flex-row'>
                            <input 
                                type='url' 
                                name='link'
                                placeholder="https://www.youtube.com/watch?v=x-EQJS53TAg" 
                                style={{width: '100%'}}
                                value={links || ""}
                                onChange={(e)=>{handleInputLinks(e)}}
                            />
                            <button onClick={(e)=>handleAddLinkList(e)} className="programs-list-add-btn">+</button>
                        </div>
                        <ul className='align-self-start' style={{width:"100%"}}>
                            {programDetails?.links?.map((data,index)=>(
                                <div key={index} className='d-flex flex-row mt-3'>
                                    <li className="mb-0" style={{width:"100%",fontSize:"12px"}}>{`${data}`}</li> <button onClick={(e)=>handleRemoveLinkList(e, index)} className="programs-list-close-btn">X</button>
                                </div>
                            ))}
                        </ul>
                    </div>
                    <div className="d-flex flex-column mb-3 edit-input-box">
                        <div className="d-flex flex-column flex-sm-row edit-input-box">
                            <div className='d-flex flex-column me-3 mb-3'>
                                <label>Publish Start</label>
                                <input 
                                    type='date' 
                                    name='publishStartDate'
                                    value={programDetails?.publishStartDate?.split('T')[0] || ""}
                                    onChange={(e)=>{handleSetPublishStartDate(e)}}
                                />
                            </div>
                            <div className='d-flex flex-column me-3'>
                                <label>Publish End</label>
                                <input 
                                    type='date' 
                                    name='publishEndDate'
                                    disabled={!programDetails?.publishStartDate}
                                    value={programDetails?.publishEndDate?.split('T')[0] || ""}
                                    onChange={(e)=>{handleSetPublishEndDate(e)}}
                                />
                            </div>
                        </div>
                    </div>
                    <button className='edit-form-buttons' disabled={programUpdate ? false : true} style={{opacity:programUpdate ?"1":"0.5"}}>UPDATE PROGRAM</button>
                </form>
            </div>
        </div>
    </>
  )
}

export default ProgramUpdateForm